import React from "react"

const RecipientData = () => {

    return (
        <div style={{ color: "rgb(4, 60, 124)" }}>
            Elles sont destinées aux services habilités de in’li PROPERTY MANAGEMENT, le cas échant à ses partenaires et prestataires intervenant sur le patrimoine. 

        </div>
    )


}

export default RecipientData