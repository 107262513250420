import { sw } from "../actions/types"

const defaultState = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null
}



const ServiceWorkerReducer = (state = defaultState, action = {}) => {
    switch(action.type) {
         case sw.SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case sw.SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    default:
      return state;
  }

}

export default ServiceWorkerReducer