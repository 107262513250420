import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    divider: {
        margin: "0.1em"
    },
    button: {
        margin: theme.spacing(0),
        color:'#F0346C'
    },
    buttonBills: {
        marginTop: "2em",
        marginLeft: "1.6em",
        width: 850,
        height: "12%",
        backgroundColor: "#F0346C",
        fontSize: "12px",
     },
    buttonBillsDisable: {
        marginTop: "1em",
        height: "6%",
        marginLeft: "1.6em",
        width: 860,
        backgroundColor: "#F0346C",
        fontSize: "12px",
     },
    paperMain: {
        color: "#043C7C",
        margin: "2em 1em 1em 1em",
        padding: "2em 0.5em 1em 0.1em",
        fontSize: "12px",
        width:"auto"
    },
    titleWelcome: {
        color:"#F0346C"
    }
}));
export const Styles = {
    button: {
        margin: "0 0.2em 0 0.2em",
        fontWeight: "bold"
    },
    userInfodiv: {
        fontSize:"13px",
    
   },
 
}

export const StylesSpaceComponent = {
    containerComp: {
        display: "flex"
    },
    userInfodiv: {
        width:"30%",  
        textAlign:"center"
    },

}

export default useStyles