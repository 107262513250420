import { spaceUser } from "./types"
import axios from "axios"
import { batch } from 'react-redux'
import history from "../main/history"


export const change = (name, value) => ({
    type: spaceUser.SET_DETAILSUSER_FIELD,
    name,
    value
})
export const getUserBillsData = (value) => ({
    type: spaceUser.GET_USERBILLSDATA,
    value
})

export const setError = (id, message) => ({
    type: spaceUser.SET_ERROR_DTL,
    id,
    message
})

export const setAccessBills =  (url,value, accessing) => ({
    type: spaceUser.SET_BREADCRUMBS,
    url,
    value,
    accessing
})

export const removeBreadCrumbs =  (url) => ({
    type: spaceUser.REMOVE_BREADCRUMBS,
    url
})

export const thunkBills = (value, valuebis) => {
    return (dispatch, getState) => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/tenant/bills/${value.buildingCode}/${value.leaseNumber}`, {
            headers: {
                "X-API-KEY": process.env.REACT_APP_API_KEY
            }
        })
        .then(response => {
            if (response.data.isSuccess === true) {
                batch(() => {
                    dispatch(setError(404, ''))
                    dispatch(getUserBillsData(response.data.content))
                    dispatch(setAccessBills('/spaceuser/bills', 'Mes \u00c9ch\xe9ances', !getState().dtl.accessBills.isBill))
                    history.push(`spaceuser/bills/${valuebis}`) 
                })
            }

        })
        .catch((e) => {
            dispatch(setError(404, 'Factures indisponibles'))
        });
    }
}

/*export const thunkBillsChangeStatus = (value) => {
    return (dispatch, getState) => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/tenant/bills/${value.buildingCode}/${value.leaseNumber}`, {
            headers: {
                "X-API-KEY": process.env.REACT_APP_API_KEY
            }
        })
            .then(response => {
                if (response.data.isSuccess === true) {
                    batch(() => {
                        dispatch(setError(404, ''))
                        dispatch(getUserBillsData(response.data.content))
                        dispatch(PayPage(false))
         
                    })
                        setTimeout(() => { window.location.reload(true) }, 1000)
                }
                
            })
            .catch((e) => {
                dispatch(setError(404, 'Factures indisponibles'))
            });
    }
}*/

