import React from "react"
import ImgNotFound from "../../assets/outside/sadbot.png"
import ReduceNavBar from "../layouts/header/ReduceNavError"
import Styles, { ColorButton } from "../../styles/errors.js"
import { thunkClearNoReload } from "../../actions/mainAction"


const NotAllowed = (props) => {
   const { dispatch } = props
   const handleRedirection =  (e) => {
     e.preventDefault()
     dispatch(thunkClearNoReload())
   }

    return (
        <>
        <ReduceNavBar bgColor="#F0346C" title="403 Non autorisé" color="white" />
            <div className="area" style={Styles.area}>
          <img src={ImgNotFound} alt="not found"/>
          <ColorButton variant="outlined" color="primary"  onClick={handleRedirection}>
            Revenir en arrière
          </ColorButton>
        </div>
     </>
    )
}

export default NotAllowed