import { bills, sw } from "./types"
import axios from "axios"
import { batch } from 'react-redux'

export const setInit = () => ({
    type: sw.SW_INIT,

})

export const setUpdate = () => ({
    type: sw.SW_UPDATE,

})
export const setRow = (value) => ({
    type: bills.SET_ROWS,
    value
})
export const setRowMob = (value) => ({
    type: bills.SET_ROWS_MOB,
    value
})

export const setPageNum = (page) => ({
    type: bills.SET_PAGE_NUM,
    page
})


export const getBillsData = (value) => ({
    type: bills.GET_BILLSDATA,
    value
})


export const setError = (id, message) => ({
    type: bills.SET_ERROR_BLS,
    id,
    message
})


export const thunkBillsNoPush = (value) => {
    return (dispatch, getState) => {
      axios.get(`${process.env.REACT_APP_API_ENDPOINT}/tenant/bills/${value.buildingCode}/${value.leaseNumber}`, {
                headers: {
              "X-API-KEY":process.env.REACT_APP_API_KEY
                }
            })
            .then(response => {
                if (response.data.isSuccess === true) {
                    const countData = response.data.content.length
                    //batch() to ensure that multiple actions dispatched outside of React only result in a single render update
                    batch(() => {
                        dispatch(setError(404, ''))
                        dispatch(setRowMob(countData))
                        dispatch(getBillsData(response.data.content))
                    })
                } 
            })
            .catch((e) => {
                 dispatch(setError(404, 'Factures indisponibles'))  
            });
       }
}

