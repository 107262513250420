import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            fontSize: "12px",
            maxWidth: "80%",
            width: "80%"
        },
        "& label": {
            color: '#00AF98'
        },
        "& label.Mui-error.Mui-focused": {
            color: 'red'
        },
        "& label.Mui-focused": {
            color: '#00AF98'
        },
        "& .Mui-error": {
            '&:hover fieldset': {
                borderColor: 'red',
            },
            "& input:valid:focus + fieldset": {
                borderColor: 'red'
            } ,
            "& input:invalid + fieldset" : {
                borderColor: 'red',

            },
           '& fieldset': {
                borderColor: 'red',
            }
        },
  
        "& .MuiOutlinedInput-root": {
            '&:hover fieldset': {
                borderColor: '#00AF98',
            },
            "& input:valid:focus + fieldset": {
                borderColor: '#00AF98'
            },
            '& fieldset': {
                borderColor: '#00AF98',
            },
        },
    },
    help: {
        color: '#043C7C'
    },
    dialogTitle: {
        color: '#043C7C'
    }

}))

export const Styles = (data, data2, data3) => ({
    card: {
        position: data ? "relative" : data3 ? "absolute" : data2 ? "absolute" : 'absolute',
        top: data ? "20%" : data3 ? "30%" : data2 ? "20%" : "39%",
        left: data ? "-20%" : data3 ? "58%" : data2 ? "65%" : "60%" ,
        textAlign: "center",
        transform: data ? "translate(20%, 20%)" :  data3 ? "translate(-30%, -30%)" : data2 ? "translate(-15%, -15%)" : "translate(-39%, -39%)",
        marginBottom: data ? "1em" : data2 ? "2em" : "4em",
        padding: data ? "0em" : data2 ? "1em" : "5em",
        minWidth: data ? 340 : data3 ? 370 : data2 ? 400 : 611,
        maxHeight: data ? 430 : data3 ? 550 : data2 ? 620 : 685,
        zoom: data ? "80%" : data3 ? "80%" : data2 ? "99%" : "100%",
        height: "auto"
    },
    LeftSide: {
        position: data ? "relative" :"absolute",
        top: data ? "9%" :  data3 ? "15%" : data2 ? "24%" : "24%",
        left: data ? "-5%" : data3 ? "-10%" : data2 ? "-1%" : "-0.8%",
        textAlign: "center",
        padding:"1em",

    },
    divButton: {
        margin: data ? "1em 0 1em 0" : data2 ? "1em 0 1em 0" : "4em 0 4em 0",
    },
    button: {
        width: data ? "30%" : data2 ? "50%" : "70%",
        height: data ? "15%" : data2 ? "25%" : "40%"
    },
    h3: {
        textAlign: "center", 
        color:"#043C7C"
    },

    img: {
    maxWidth: data ? "90vw" : data3 ?  "50vw" : data2 ? "40vw" : "45vw",
    padding: "1em"
    
    }
})
  
export default useStyles