import React, { lazy, Suspense } from "react"
import { Styles } from "../../styles/identity"
import '../../styles/identity.css'
import Svg from "../../assets/images/inli.svg"
import { useMediaQuery } from '../../main/hooks';
import { connect } from 'react-redux'
import {
    Footer, SupportBrowser, BrowserSupportBody } from "../../components/index"
import { isIE } from 'react-device-detect'

const Form = lazy(() => import('./../../components/identity/Form'));

const MainScreen = ({ dispatch, fields, errors, success }) => {

    const mediaMatch = useMediaQuery('(max-width: 500px)');
     const mediaMatchHeight = useMediaQuery('(max-height: 700px)');
    const mediaMatch2 = useMediaQuery('(max-width: 1450px)');
    const mediaMatch3 = useMediaQuery('(max-width: 900px)');
   
        if (isIE) 
            return (
                <div>
                    <SupportBrowser bgColor="#F0346C" title="Navigateur non compatible" color="white" />
                    <BrowserSupportBody />
                </div>
            )
            return (
               <div className="container">
                <div className="LeftSide">
                    <Suspense fallback={<div>Chargement...</div>}>
                        <Form {...{ fields, dispatch: dispatch, errors, success }} />
                    </Suspense>
                </div>
                <div className="RightSide" style={Styles(mediaMatch, mediaMatch2, mediaMatch3).LeftSide}>
                    <img src={Svg} alt="Logo"  style={Styles(mediaMatch, mediaMatch2, mediaMatch3).img}/>
                </div>
                {mediaMatch && mediaMatchHeight
                ?
                <Footer pos="relative" bot={-40} color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'}/>
                :
                 mediaMatch && !mediaMatchHeight
                  ?
                   <Footer pos="absolute" bot={0} color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'}/>
                  :
                 mediaMatch3
                    ?
                     <Footer pos="absolute" bot={-160} color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'}/>
                    :
                     <Footer pos="absolute" bot={0} color={"white"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'}/>
        
                }
                </div>
        )
}

const mapStateToProps = (state) => ({
    fields: state.idt.fields,
    success: state.idt.success,
    errors: state.idt.errors,
});


export default connect(mapStateToProps)(MainScreen);
