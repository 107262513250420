import React from 'react'
import {
        Button, 
        Card,
        CardActions,
        CardContent,
        Typography
    } from '@material-ui/core';
import { thunkClearNoReload }  from "../../actions/mainAction"
import { styleMobileProfil } from "../../styles/mobile"


function Profil (props) {
    const { data, dispatch } = props
    const classes = styleMobileProfil();
    const bull = <span className={classes.bullet}>•</span>

    const handleClick =  () => {
        dispatch(thunkClearNoReload())
    }

    return (
        <>
         <Card className={classes.root} variant="outlined">
              <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {data.leaseNumber}{bull}{props.data.buildingCode}
                    </Typography>
                    <Typography variant="h5" component="h2" color='secondary'>
                      {data.fullName}
                    </Typography>
                    <Typography variant="subtitle2" color='primary'>
                        Email: {data.email !== null ? data.email : <Typography variant="body1" color='secondary'>Veuillez communiquer votre email auprès du service client, sans quoi vous ne recevrez pas vos reçus de paiement</Typography>}
                    </Typography>
                    <Typography variant="subtitle2" color='primary'>
                        Tel: {data.phoneNumber !== null ? data.phoneNumber : <Typography variant="body1" color='secondary'>Veuillez communiquer votre téléphone auprès du service client.</Typography> }
                    </Typography>
                    <Typography variant="body2">
                      {data.address}
                      <br />
                      {data.zipCode} {props.data.city}
                    </Typography>
              </CardContent>
         </Card>
         <Card className={classes.root} variant="outlined">
              <CardActions>
                    <Button size="large" variant='outlined' color='secondary' onClick={handleClick}>Se déconnecter</Button>
              </CardActions>
         </Card>
       </>
    );
}

export default Profil