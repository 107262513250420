import generator from "../utils/Generator"
import { identity, router } from "./types"
import { setAccessBills } from "./spaceUserAction"
import { batch } from 'react-redux'
import axios from "axios"
import { push } from 'connected-react-router'

export const setError = (id, message) => ({
    type: identity.SET_ERROR_IDT,
    id,
    message
})

export const setEmail = (email) => ({
    type: identity.SET_EMAIL,
    email
})
export const change = (name, value) => ({
    type: identity.SET_IDENTITY_FIELD,
    name,
    value
})

export const isSuccess = (isSuccess) => ({
    type: identity.SET_SUCCESS,
    isSuccess
})

export const getUserAuthData = (value) => ({
    type: identity.GET_USERAUTHDATA,
    value
})
export const clearRoute = () => ({
    type: router.RESET_ROUTE

})

/*function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}*/

export const ThunkLogin =  (value) => {
    return (dispatch, getState) => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/tenant/${value.field1}/${value.field2}/${value.field3}`, {
            headers: {
                "X-API-KEY":process.env.REACT_APP_API_KEY
            }
        })
            .then(response => {
                
                if (response.data.isSuccess === true) {
                    //batch() to ensure that multiple actions dispatched outside of React only result in a single render update
                    generator(response.data.content)
                    batch(() => {
                            dispatch(getUserAuthData(response.data.content))
                            dispatch(setEmail(getState().idt.userData.email))
                            dispatch(setError(404, ''))
                            dispatch(setError(500, ''))
                            dispatch(isSuccess(true))
                            dispatch(setAccessBills('/spaceuser', 'Mon Espace', getState().dtl.accessBills.isBill))
                            dispatch(push('/spaceuser'))

                    })
                }
            
            })
         
            .catch((e) => {
                if (e.response && e.response.data.isSuccess === false) {
                     batch(() => {
                             dispatch(setError(500, ''))
                         dispatch(setError(404, 'Identifiants incorects ou utilisateur introuvable'))
                    }) 
                } else {
                    batch(() => {
                        dispatch(setError(404, ''))
                        dispatch(setError(500, 'Serveur interne ou reseau. Veuillez essayer ulterieurement'))
                    })
                }
            });
    }
 }

