import { identity } from "../actions/types"

const defaultState = {
    fields:
    {
        field1: "",
        field2: "",
        field3: ""
    },
    errors: { 404: '', 500: ''},
    success: false,
    userData: {},
    email: ''
}

const IdentityReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case identity.GET_USERAUTHDATA:
            return {
                ...state,
                userData: action.value
            }
       case identity.GET_USERBILLSDATA:
            return {
                ...state,
                billsData: action.value
            }
        case identity.SET_IDENTITY_FIELD:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.name]: action.value
                }
            }
        case identity.SET_EMAIL:
            return {
                ...state,
                email: action.email
            }
        case identity.SET_SUCCESS:
            return {
                ...state,
                success: action.isSuccess
            }

        case identity.SET_ERROR_IDT: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.id]: action.message
                }
            }
        }
        default:
            return state 
        }
}
export default IdentityReducer