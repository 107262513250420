const Styles = () => ({
    divMain: {
        color: "rgb(4, 60, 124)",
        marginTop: "10px",
        marginLeft: "30px",
        padding: "1em"
    },
    divlink: {
        padding: "0.5em",
    },
    link: {
        marginLeft: "1em"
    }
})

export const StylesHelpConnexion = {
    divDataCo: {
        margin: "2em"
    },
    h6: {
        textAlign: "center",
        color: "rgb(4, 60, 124)",
        textDecoration: "underline"
    },
    generalDiv: {
        display: "inline-block",
        margin: "0.5em"
    },
    generalImg: {
        width: "10em",
        cursor: "grab" 
    }

}

export default Styles