import React, { useState, useEffect } from "react"
import "../../styles/errors.css"
import ImgNotFound from "../../assets/outside/sadbot.png"
import Error404 from "../../assets/outside/Error404.jpg"
import { ColorButton } from "../../styles/errors.js"
import { thunkUserSpaceNoReload } from "../../actions/mainAction"
const NotFound = (props) =>  {

    var eye = document.getElementById("pupil")
    const [distancePosition, setDistancePosition] = useState(0)
    const [distancePositionbis, setDistancePositionbis] = useState(0)
    const [needForRAF, setNeedForRAF] = useState(true)
    const [loading, setLoading] = useState(false)
    
   const handleRedirection =  (e) => {
     e.preventDefault()
     props.dispatch(thunkUserSpaceNoReload())
   }

    const update = () => {
         if (loading) {   
          setNeedForRAF(true); // rAF now consumes the movement instruction so a new one can come
          eye.style.transform = `translate( ${distancePosition}px , ${distancePositionbis}px)`;// move it!
        }

      }
    const handleMove = (e) => {
    e.preventDefault();
         setDistancePosition(e.nativeEvent.clientX)
         setDistancePositionbis(e.nativeEvent.clientY)

        //( e.nativeEvent.clientX - startPosition) + currentPosition;
     if (needForRAF) {
        setNeedForRAF(false);            // no need to call rAF up until next frame
        window.requestAnimationFrame(update); // request 60fps animation
        }; 

    }   
    useEffect(() => {setLoading(true)}, [])
    return (
    <div className="move-area" style={{textAlign:"center",marginTop:"2em"}} onMouseMove={(e) => handleMove(e) }>
      <img src={ImgNotFound} alt="not found" style={{width:100}}/>
      <img src={Error404} alt="erreur 404" style={{width:100}}/>
      <div className='contain'>
            <div id="pupil" className="pupils">404</div>
      </div>
      <ColorButton variant="outlined" color="primary"  onClick={handleRedirection}>
        Retourner à votre espace personnel
      </ColorButton>
    </div>
    )

}

export default NotFound