import React, { useState } from "react"
import {
    Button,
    Card,
    IconButton,
    TextField
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useMediaQuery } from '../../main/hooks';
import LoaderCust from "../utils/LoaderCust"
import { ThunkLogin, change, setError } from '../../actions/identityAction'
import HelperDialog from './HelperDialog'
import useStyles, { Styles } from "../../styles/identity"


const Form = (props) => {
    const { dispatch, fields, errors } = props
    const classes = useStyles();
    const mediaMatch = useMediaQuery('(max-width: 520px)');
    const mediaMatch2 = useMediaQuery('(max-width: 1900px)');
    const mediaMatch3 = useMediaQuery('(max-width: 850px)');

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    //const [err, setErr] = useState('');
    const [form, setForm] = useState({field1:'', field2: '', field3: ''})
    const [loading, setLoading] = useState(false)
    

    window.onload = () => {
         if(errors["404"]) {
               dispatch(setError(404, '')) 
           }
         if(errors["500"]) {
               dispatch(setError(500, ''))
           }
       return
    }

    const onChange = (event) => {
        event.preventDefault()
        const { name, value } = event.target
        setForm({...form,[name]: value})
        dispatch(change(name, value));
    }



    const handleClickHelper = (val) => {
        setOpen(true)
        setTitle(val)
    }

    const handleClickHelperClose = () => {
        setOpen(false);
    }

    const onSubmit = (event) => {
        event.preventDefault()
        return (fields.field1.length === 4 || fields.field1.length === 5) && fields.field2.length > 1 && fields.field3.length === 8 ?
            <>
                {setLoading(true)}
                {dispatch(ThunkLogin(fields))}
            </>
            :
          ""
    }

    return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={onSubmit}>
        <Card style={Styles(mediaMatch, mediaMatch2, mediaMatch3).card}>
            {Object.keys(errors).length > 0 && (errors["404"] !==  "" || errors["500"] !==  "")
                ?
                <Alert severity="error">
                    <AlertTitle>Erreur</AlertTitle>
                        {errors["404"]}
                        {errors["500"]}
                </Alert>
                :
                ""
            }
            <h3 style={Styles().h3}>Identifiez-vous</h3>
            <div>
                <TextField 
                    type='tel' 
                    error={form.field1.length < (4 || 5)} 
                    helperText={form.field1.length < (4 || 5) ? "4 ou 5 chiffres visibles sur l'avis d'échéance" : ""} 
                    inputProps={{ maxLength: 5}} 
                    id="outlined-basic" 
                    label="Code immeuble" 
                    variant="outlined" 
                    name="field1" 
                    value={form.field1} 
                    onChange={onChange} 
                    required/>
                <IconButton size='small' color="primary" aria-label="help icon" className={classes.help} onClick={() => handleClickHelper('Code Immeuble')}>
                    <HelpOutlineIcon />
                </IconButton>
            </div>
            <div>
                <TextField 
                    type='tel' 
                    error={form.field2.length < 2} 
                    helperText={form.field2.length < 2 ? "2 à 5 chiffres visibles sur l'avis d'échéance" : ""} 
                    inputProps={{ maxLength: 10 }} 
                    id="outlined-basic2" 
                    label="Code bail" 
                    variant="outlined" 
                    name="field2" 
                    value={form.field2} 
                    onChange={onChange} 
                        required />
                    <IconButton size='small' color="primary" aria-label="help icon" className={classes.help} onClick={() => handleClickHelper('Code Bail')}>
                        <HelpOutlineIcon />
                    </IconButton>
                <TextField
                    type="password"
                    error={form.field3.length < 8}
                    helperText={form.field3.length < 8 ? "8 chiffres" : ""}
                    inputProps={{ maxLength: 8 }}
                    id="outlined-basic3"
                    label="Code d'accès"
                    variant="outlined"
                    name="field3"
                    value={form.field3}
                    onChange={onChange}
                    required />
                <IconButton size='small' color="primary" aria-label="help icon" className={classes.help}>
                        <HelpOutlineIcon style={{ color: "white" }}/>
                </IconButton>
            </div>
            <div className={'divButton'} style={Styles(mediaMatch, mediaMatch2).divButton}>
                    {loading && (errors["404"] === "" && errors["500"] === "")
                    ?
                  
                        <Button 
                            id="buttonT" 
                            type="button" 
                            variant="outlined" 
                            color="secondary" 
                            disabled>
                            <LoaderCust 
                                type="Bars"
                                color="#043C7C"
                                height={50}
                                width={185}
                             />
                        </Button>
                        :
                       <Button 
                            id="button" 
                            type="submit" 
                            variant="outlined" 
                            color="primary" 
                            style={Styles(mediaMatch, mediaMatch2).button} 
                            size="large">
                            S'identifier
                      </Button>
                
                }
            </div>
            <HelperDialog close={handleClickHelperClose} op={open} title={title} />
        </Card>
        </form>
        )
        }

export default Form