import React, { useState } from "react"
import codeIm from "../../assets/images/codeIm.PNG"
import codeBail from "../../assets/images/codeBail.PNG"

//Component to help users for identification
const ImBills = (props) => {
    const { title, width, widthout } = props
    const [ zoom, setZoom] = useState({ width: width, pointer: "zoom-in"})

    const handleZoom = () => {
        return zoom.pointer === "zoom-in" && zoom.width === width
        ?
        setZoom({ width: widthout, pointer: "zoom-out" }) 
        :
        setZoom( {width: width, pointer: "zoom-in"})
    }

   return title === 'Code Immeuble'
    ?
   <img src={codeIm} alt='code immeuble' style={{width: zoom.width, cursor: zoom.pointer}} onClick={handleZoom}/>
    :
   <img src={codeBail} alt='code bail'  style={{width: zoom.width, cursor: zoom.pointer }} onClick={handleZoom}/>

}

export default ImBills