import { payment } from "../actions/types"

const defaultState = {
    dataTransaction: {},
    errors: { 404 : ''}
}



const PaymentReducer = (state = defaultState, action = {}) => {
    switch(action.type) {
        case payment.RETRIEVE_PREDATA_TRANSACTION:
            return {
                ...state,
                dataTransaction: action.value
            }
        case payment.SET_ERROR_PAT: 
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.id]: action.message
                }
            }
        default:
            return state
    }

}

export default PaymentReducer