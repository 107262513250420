import React, { Component } from "react"
//import LogicUpdate from "./LogicUpdate"
import Navigation from "./navigation"


class Main extends Component {
    render() {
       return <>
            <Navigation />
        </>
    }
}

export default Main