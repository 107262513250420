import { spaceUser } from "../actions/types"

const defaultState = {
    billsData: {},
    errors: { 404: ""},
    accessBills: {
        breadCrumbs: {},
        isBill: false
    }
}

const SpaceUserReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case spaceUser.GET_USERBILLSDATA:
            return {
                ...state,
                billsData: action.value
            }
         case spaceUser.SET_BREADCRUMBS:
            return {
                ...state,
                accessBills: {
                    ...state.accessBills,
                    breadCrumbs: {
                        ...state.accessBills.breadCrumbs,
                        [action.url]: action.value
                    },
                    isBill: action.accessing
                }
            }
            case spaceUser.REMOVE_BREADCRUMBS:
            return {
                ...state,
                accessBills: {
                    ...state.accessBills,
                    breadCrumbs: {
                        ...state.accessBills.breadCrumbs,
                        [action.url]: ''
                    }
                }
            }
        case spaceUser.SET_ERROR_DTL: 
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.id]: action.message
                }
            }
        default:
            return state
    }
}
export default SpaceUserReducer