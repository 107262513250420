export const sw = {
      SW_INIT:'SW_INIT',
      SW_UPDATE: 'SW_UPDATE'
}

export const identity = {
    GET_USERAUTHDATA: "GET_USERAUTHDATA",
    SET_IDENTITY_FIELD: "SET_IDENTITY_FIELD",
    SET_ERROR_IDT: "SET_ERROR_IDT",
    SET_SUCCESS: "SET_SUCCESS",
    SET_EMAIL: "SET_EMAIL"
}

export const spaceUser = {
    SET_DETAILSUSER_FIELD: "SET_DETAILSUSER_FIELD",
    GET_USERBILLSDATA: "GET_USERBILLSDATA",
    SET_BREADCRUMBS:"SET_BREADCRUMBS",
    REMOVE_BREADCRUMBS: "REMOVE_BREADCRUMBS",
    SET_ERROR_DTL: "SET_ERROR_DTL"
}

export const bills = {
    SET_ROWS: "SET_ROWS",
    SET_PAGE_NUM: "SET_PAGE_NUM",
    SET_ROWS_MOB: "SET_ROWS_MOB",
    GET_BILLSDATA:"GET_BILLSDATA",
    SET_ERROR_BLS: "SET_ERROR_BLS"
}

export const payment = {
    RETRIEVE_PREDATA_TRANSACTION: "RETRIEVE_PREDATA_TRANSACTION",
    SET_ERROR_PAT: "SET_ERROR_PAT"
}

export const router = {
    RESET_ROUTE: "RESET_ROUTE"
}