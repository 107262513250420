import React from "react"

const Finality = () => {
    return (
        <div style={{ color: "rgb(4, 60, 124)"}}>
            Le responsable de traitement met en œuvre un traitement de données vous concernant ayant pour finalité 
            la réponse aux demandes des personnes ayant souhaité entrer en contact avec elle.
        </div>
    )

}

export default Finality