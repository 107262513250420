import React from "react";
import {
    Switch,
    Route
} from "react-router-dom";
import { MainScreen, SecondScreen, SecondScreenForce, NotAllowedScreen, TimeoutScreen } from "../containers/index"
import { Contact, Legacy, Faq, HelpConnexion, SpaceUser, HelpSeeBills, HelpPayment, CookiePolicy, PersonalData } from "../components/index"
import { connect } from 'react-redux'
import { check } from "../utils/Generator"



const Navigation = ({ codeB, codeI, nameUser,  dispatch, success }) => {

    const StatusTok = check()
    return StatusTok !== 404
        ?
        success
            ?
            <>
                <Switch>
                    <Route exact path="/" component={SecondScreenForce} />
                    <Route path="/spaceuser" component={SecondScreen} />
                    <Route path="/contact" component={() => <Contact check={success} dispatch={dispatch} codeB={codeB} codeI={codeI} nameUser={nameUser} />} />
                    <Route path="/legacy" component={() => <Legacy check={success} dispatch={dispatch} />} />
                    <Route path="/faq" component={() => <Faq check={success} dispatch={dispatch} />} />
                    <Route path="/helpforconnexion" component={() => <HelpConnexion check={success} dispatch={dispatch} />} />
                    <Route path="/helpforspaceuser" component={() => <SpaceUser check={success} dispatch={dispatch} />} />
                    <Route path="/helpforbills" component={() => <HelpSeeBills check={success} dispatch={dispatch} />} />
                    <Route path="/helpforpayment" component={() => <HelpPayment check={success} dispatch={dispatch} />} />
                    <Route path="/cookiepolicy" component={() => <CookiePolicy check={success} dispatch={dispatch} />} />
                    <Route path="/personaldata" component={() => <PersonalData check={success} dispatch={dispatch} />} />
                </Switch>
            </>
            :
            <Switch>
                <Route exact path="/" component={MainScreen} />
                <Route path="/contact" component={() => <Contact check={success} dispatch={dispatch} codeB="Code Bail" codeI="Code Immeuble" nameUser="Nom et Pr�nom" />} />
                <Route path="/legacy" component={() => <Legacy check={success} dispatch={dispatch} />} />
                <Route path="/faq" component={() => <Faq check={success} dispatch={dispatch} />} />
                <Route path="/helpforconnexion" component={() => <HelpConnexion check={success} dispatch={dispatch} />} />
                <Route path="/helpforspaceuser" component={() => <SpaceUser check={success} dispatch={dispatch} />} />
                <Route path="/helpforbills" component={() => <HelpSeeBills check={success} dispatch={dispatch} />} />
                <Route path="/helpforpayment" component={() => <HelpPayment check={success} dispatch={dispatch} />} />
                <Route path="/cookiepolicy" component={() => <CookiePolicy check={success} dispatch={dispatch} />} />
                <Route path="/personaldata" component={() => <PersonalData check={success} dispatch={dispatch} />} />
                <Route path="/*" component={NotAllowedScreen} />
            </Switch>
        :
        <Switch>
            <Route path="/*" component={TimeoutScreen} />
        </Switch>
}

const mapStateToProps = (state) => ({
    success: state.idt.success,
    codeB: state.idt.userData.leaseNumber,
    codeI: state.idt.userData.buildingCode,
    nameUser: state.idt.userData.fullName
});


export default connect(mapStateToProps)(Navigation);