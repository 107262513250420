import React from "react"
import { connect } from 'react-redux'
import { NotAllowed } from "../../components/index"

const NotAllowedScreen = ({dispatch}) => (
    <NotAllowed {...{dispatch: dispatch}}/>

)
const mapStateToProps = (state) => ({
  
});


export default connect(mapStateToProps)(NotAllowedScreen);
