import { combineReducers } from 'redux';
import IdentityReducer from './identityReducer'
import SpaceUserReducer from './spaceUserReducer'
import BillsReducer from './billsReducer'
import PaymentReducer from './paymentReducer'
import ServiceWorkerReducer from './serviceWorkerReducer'
import { connectRouter } from 'connected-react-router';
import { router } from '../actions/types';
import storage from 'redux-persist/lib/storage'



const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    idt: IdentityReducer,
    dtl: SpaceUserReducer,
    bls: BillsReducer,
    pat: PaymentReducer,
    sw : ServiceWorkerReducer,
});

const initialState = {
    router: { location: {}, action: 'PUSH' },
};

const createRootReducer = (history) => (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        storage.removeItem('persist:app-react')
        state = undefined
    }
    if (action.type === router.RESET_ROUTE) {
        return {
            ...state,
            ...initialState
        }
    }

    return rootReducer(history)(state, action);
};

export default createRootReducer;
