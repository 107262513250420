import React from "react"
import Loader from 'react-loader-spinner'

function LoaderCust(props) {
    const { type, color, height, width, timeout } = props
    return (
        <Loader
            type={type}
            color={color}
            height={height}
            width={width}
            timeout={timeout} //3 secs => 3000s
        />
     )
}

export default LoaderCust