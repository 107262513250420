import React, { useState } from "react"
import ReduceNavBar from "../../header/ReduceNav"
import ImCoEmpty from "../../../../assets/images/help/connexionEmpty.PNG"
import ImCoFilled from "../../../../assets/images/help/connexionFilled.png"
import ImErrId from "../../../../assets/images/help/errorID.PNG"
import ImErrNet from "../../../../assets/images/help/errorNetwork.PNG"
import { StylesHelpConnexion } from "../../../../styles/faq"
import ImageDialog from "./ImageDialog"
import { Link } from "react-router-dom";




const HelpConnexion = (props) => {
    const [open, setOpen] = useState(false);
    const [val, setVal] = useState('');
    const handleClickHelper = (value) => {
        setOpen(true)
        setVal(value)
    }

    const handleClickHelperClose = () => {
        setOpen(false);
    }


    return <>
        <ReduceNavBar bgColor="#F0346C" title="Aide à la connexion sur Inli'PM" color="white" check={props.check} dispatch={props.dispatch} faq={true} />
        <h6 style={StylesHelpConnexion.h6}>Informations sur les champs de connexion</h6>
        <div className="divDataCo" style={StylesHelpConnexion.divDataCo}>
            <p>Trois champs sont nécessaires à la connexion (les deux premiers sont indiqués sur vos avis d’échéances, le code d’accès vous a été envoyé sous pli séparé) :</p>
            <p>- Le code immeuble composé de 4 à 5 chiffres.</p>
            <p>- Le code Bail composé de 5 chiffres.</p>
            <p>- Le code d'accès composé de 5 chiffres. En cas de perte, vous pourrez toujours contacter le <Link to='/contact'>service client</Link> pour obtenir ce code. </p>
            <p style={StylesHelpConnexion.divDataCo}>Notez que tant que l'encadré des trois champs reste rouge vous ne pourrez pas vous connecter (voir image intitulée "Les champs")</p>
            <p>Vous pourrez réaliser une tentative de connexion uniquement si l'encadré des trois champs est vert (voir image intitulée "champ valide"),
                en cas de difficultés vous disposez d'une aide pour les deux premiers champs (dans "champ valide", icône entourée en rouge).</p>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={ImCoEmpty} alt="pics connexion empty" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('img1')} />
                <p><i>Les champs</i></p>
            </div>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={ImCoFilled} alt="pics connexion filled" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('img2')} />
                <p><i>Champ valide</i></p>
            </div>
        </div>
        <h6 style={StylesHelpConnexion.h6}>Erreurs possibles liées à la connexion</h6>
        <div style={StylesHelpConnexion.generalDiv}>
            <p>Deux possibilités  : </p>
            <p>- Une erreur liée à une mauvaise saisie d'identifiant (voir image intitulée "Erreurs d'identifiants"), nous vous invitons à ressaisir vos identifiants :  codes immeuble, bail et accès. </p>
            <p>- Une erreur liée au réseau (voir image intitulée "Erreurs réseaux"), nous vous invitons à bien vérifier votre connexion internet.</p>
            <div style={{ display: "inline-block" }}>
                <img src={ImErrId} alt="pics error id" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('img3')} />
                <p><i>Erreurs d'identifiants</i></p>
            </div>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={ImErrNet} alt="pics error network" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('img4')} />
                <p><i>Erreurs réseaux</i></p>
            </div>
        </div>
        <ImageDialog close={handleClickHelperClose} op={open} title={val} />
        </>

}

export default HelpConnexion