import React from "react"

const Right = () => {
    return (
        <div style={{ color: "rgb(4, 60, 124)" }}>
            <p>Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 et du règlement UE 2016/679 du 27 avril 2016 en matière de protection
            des données à caractère personnel, 
            dans les conditions définies par les dispositions précitées, vous disposez d’un droit d’accès, de rectification, d’effacement de vos données à caractère personnel,
            à la limitation du traitement, à la portabilité de vos données lorsque le traitement est fondé sur l’exécution du contrat ou le consentement.
            Lorsque votre consentement est sollicité, vous disposez du droit de retirer votre consentement, à tout moment,
            sans porter atteinte à la légitimité du traitement fondé sur le consentement effectué avant le retrait de celui-ci.</p>
            <p>Vous disposez du droit de s’opposer à tout moment, pour des raisons tenant à votre situation particulière, à un traitement de données à caractère personnel 
            dont la base juridique est l’intérêt légitime poursuivi par le responsable du traitement ou à la prospection commerciale.
            En cas d’exercice d’un tel droit d’opposition, le responsable de traitement veillera à ne plus traiter les données à caractère personnel
            dans le cadre du traitement concerné sauf s’il peut démontrer qu’il peut avoir des motifs légitimes et impérieux pour maintenir ce traitement.
            Ces motifs devront être supérieurs aux intérêts et aux droits et libertés de l’utilisateur, ou le traitement se justifier pour la constatation,
            l'exercice ou la défense de droits en justice.</p>
            <p>Par ailleurs, vous disposez également du droit de définir des directives relatives à la conservation, à l’effacement et à la communication de 
            vos données à caractère personnel après votre décès. La communication de directives spécifiques post-mortem et l’exercice des droits peuvent
            être exercés auprès du Délégué à la protection des données (DPO) d’in’li PROPERTY MANAGEMENT joignable : par courrier électronique à
            l’adresse suivante : dpoinlipm@inli.fr ou par courrier postal à l’adresse suivante : DPO d’in’li PROPERTY MANAGEMENT, Tour Ariane, 
            5 place de la Pyramide, 92088 Paris La Défense cedex.
            Lorsque vous adressez une demande d'exercice de droit, vous devez vous identifier par tout moyen. En cas de doute sur votre identité,
            le responsable de traitement pourra demander des informations supplémentaires apparaissant nécessaires, y compris la photocopie d'un titre d'identité portant
            la signature de la personne concernée.</p>
            <p>Vous avez également la possibilité d’introduire une réclamation auprès de la CNIL à l’adresse suivante : 3 Place de Fontenoy, TSA 80715, 75334 Paris cedex 07.</p>
        </div>
        )
}

export default Right