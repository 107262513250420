import React, { useState } from "react"
import UserSpace from "../../../../assets/images/help/userspace.png"
import Profil from "../../../../assets/images/help/profilmobile.png"
import { StylesHelpConnexion } from "../../../../styles/faq"
import ImageDialog from "./ImageDialog"
import ReduceNavBar from "../../header/ReduceNav"
import { Link } from "react-router-dom";




const SpaceUser = (props) => {
    const [open, setOpen] = useState(false);
    const [val, setVal] = useState('');
    const handleClickHelper = (value) => {
        setOpen(true)
        setVal(value)
    }

    const handleClickHelperClose = () => {
        setOpen(false);
    }

    return <>
        <ReduceNavBar bgColor="#F0346C" title="Mon espace" color="white" check={props.check} dispatch={props.dispatch} faq={true} />
        <h6 style={StylesHelpConnexion.h6}>Informations générales</h6>
        <div className="divDataCo" style={StylesHelpConnexion.divDataCo}>
            <p>Vous souhaitez obtenir un document (copie de bail, quittance, attestation de loyer, relevé de compte),
                merci de nous faire parvenir votre demande par mail en cliquant sur le bouton ou le lien "Nous écrire" dans la rubrique <Link to='/contact'>Contact</Link> .</p>
        </div>
        <h6 style={StylesHelpConnexion.h6}>Espace personnel sous ordinateur</h6>
        <div className="divDataCo" style={StylesHelpConnexion.divDataCo}>
            <p> L'espace personnel est composé d'un rappel des informations utilisateurs sur la gauche et au centre une barre rouge permettant de consulter les échéances en vue de les payer (voir image intitulée "Espace utilisateur, sur PC") </p>
            <p>Attention vérifier bien que vos coordonnées mail et téléphone sont valides afin de recevoir la validation de votre paiement.
                Si ce n’est pas le cas nous vous invitons à nous communiquer vos nouvelles coordonnées  en cliquant sur le lien ou bouton "nous écrire" dans la rubrique <Link to='/contact'>Contact</Link> en bas à droite de votre écran. </p>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={UserSpace} alt="pics space user on desktop" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgspace1')} />
                <p><i>Espace utilisateur, sur PC</i></p>
            </div>
        </div>
        <h6 style={StylesHelpConnexion.h6}>Espace personnel sous mobile</h6>
        <div className="divDataCo" style={StylesHelpConnexion.divDataCo}>
            <p>L'espace personnel sous mobile, se décline différemment, l'onglet profil contient les données utilisateurs, ainsi qu'un moyen de se déconnecter de l'application (voir image intitulée "Espace utilisateur, sous mobile")</p>
            <p>Attention vérifier bien que vos coordonnées mail et téléphone sont valides afin de recevoir la validation de votre payement.
                Si ce n’est pas le cas nous vous invitons à nous communiquer vos nouvelles coordonnées en cliquant sur le lien ou bouton "nous écrire" dans la rubrique <Link to='/contact'>Contact</Link> en bas à droite de votre écran.</p>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={Profil} alt="pics space user on mobile" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgspace2')} />
                <p><i>Espace utilisateur, sous mobile</i></p>
            </div>
        </div>
        <ImageDialog close={handleClickHelperClose} op={open} title={val} />
        </>

}

export  default SpaceUser