import React, { useState } from "react"
import { Button, Input, InputLabel, FormControl  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Create, Send } from '@material-ui/icons';
import ReduceNavBar from "../header/ReduceNav"
import Footer from "./index"
import { Link } from "react-router-dom";
import { ChiefTreatment, Finality, Right, RecipientData, Data, BaseJustice}from "./mention/index"
import Styles from "../../../styles/contact"

const useStyles = makeStyles((theme) => ({
 
    root: {
        margin: theme.spacing(1),
    },
    button: {
        margin: "0em"
    },
    margin: {
        marginRight: theme.spacing(6),
        paddingBottom: "1em"
    }
}));

const Contact = (props) => {
   
    const classes = useStyles();
    const { check, dispatch, codeB, codeI, nameUser } = props
    const [write, setWrite] = useState(false)
    const [more, SetMore] = useState(false)
    const [mention, setMention] = useState({id:'RT'})



    const handleMoreMention = () => {
        SetMore(!more)
    }
    const handleClickSpecificMention = (e, mention) => {
    
        setMention({ ...mention, id: e.currentTarget.id })
  
    

    }
    const displayMention = () => {
        switch (mention.id) {
            case 'FT':
                return <Finality />
            case 'VD':
                return <Right />
            case 'BS':
                return <BaseJustice />
            case 'DC':
                return <Data />
            case 'DD':
                return <RecipientData />
            default:
                return <ChiefTreatment />
        }
    }
    const handleClick = () => {
        setWrite(true)
    }

    return <>
        <ReduceNavBar bgColor="#F0346C" title="Service relation client" color="white" check={check} dispatch={dispatch} />
        <div className='content' style={Styles.divContent}>
            <h6>In’li Property Management</h6>
            <p>Service Clients</p>
            <p>Tour Ariane - 5, place de la Pyramide</p>
            <p>La Défense 92088 PARIS LA DEFENSE CEDEX</p>
            <p>Tél. : 01 76 24 14 14</p>
            {check
                ?
                <p>
                    <a
                        href={`mailto:serviceclients@inlipm.fr
                    ?Subject=Mon%20paiement%20sur%20Inli'PM,%20Code%20Bail%20:%20${codeB}.
                    &body=Bonjour,%0AEcrivez à notre service...%0A%0ACordialement,%0A%0A%0ARappel de vos informations : %0ANom et Prénom : ${nameUser}%0ACode Bail : ${codeB}
                    %0ACode Immeuble : ${codeI} %0A%0A`}
                    target="_top">Nous écrire</a>
                </p>
                    :
                    !write
                    ?
                    <Button
                        type="submit"
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        endIcon={<Create />}
                    >
                        Nous écrire
                    </Button>
                    :
                    <>
                    <form
                        style={Styles.formContain}
                        action="mailto:serviceclients@inlipm.fr?Subject=Mon%20paiement%20sur%20InliPM."
                        method="POST"
                        encType="text/plain">
                        <div>
                            <FormControl className={classes.margin}>
                                <Input id="intro" name=" " type="hidden" value=" Modele Inli'PM =%0ABonjour,%0AEcrivez nous...%0A%0ACordialement,%0A%0A%0ARappel de vos informations :"
                                    inputProps={{
                                        'aria-label': 'intro',
                                    }} />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.margin}>
                                <InputLabel htmlFor="inputnom">Nom</InputLabel>
                                <Input required={true} id="inputnom" name="Nom" type="text" 
                                    inputProps={{
                                        'aria-label': 'lastName',
                                    }} />
                            </FormControl>
                            <FormControl className={classes.margin}>
                                <InputLabel htmlFor="prenom">Prénom</InputLabel>
                                <Input required={true} id="prenom"  name="Prenom" type="text"
                                    inputProps={{
                                        'aria-label': 'fisrtName',
                                    }} />
                            </FormControl>
                            <FormControl className={classes.margin}>
                                <InputLabel htmlFor="codebail">Code Bail</InputLabel>
                                <Input required={true} id="codebail" name="Code Bail" type="number"
                                    inputProps={{
                                        'aria-label': 'leaseNumber',
                                    }} />
                            </FormControl>
                                <FormControl className={classes.margin}>
                                <InputLabel htmlFor="codeimmeuble">Code Immeuble</InputLabel>
                                <Input required={true} id="codeimmeuble" name="Code Immeuble" type="number"
                                    inputProps={{
                                        'aria-label': 'buildingCode',
                                    }} />
                                </FormControl>
                                <Button
                                    type="submit"
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Send />}
                                >
                                    Créer l'email
                            </Button>
                        </div>
                        <p>in’li PROPERTY MANAGEMENT en tant que responsable de traitement, met en œuvre un traitement de données vous concernant ayant pour finalité 
                        la réponse aux demandes des personnes ayant souhaité entrer en contact avec elle. Ce traitement est fondé sur l’intérêt légitime d’in’li PROPERTY MANAGEMENT. 
                        Vous disposez d’un droit d’accès, d’interrogation, de rectification, d’effacement, 
                        de limitation et d’opposition au traitement de vos données ainsi qu’un droit à la portabilité de ces dernières.
                        Pour plus de précisions sur le traitement de vos données, <Link to='/contact' onClick={handleMoreMention}>cliquez ici.</Link></p>
                    </form>
                    {more
                        ?
                        <div style={Styles.buttonDiv}>
                            <Button className='active' variant="outlined" id='RT' color={mention.id !== 'RT' ? 'primary' : 'secondary'} style={{ color: mention.id !== 'RT' ? '#3f51b5' : 'rgb(240, 52, 108)', margin:"0em 1em 1em 0em" }} onClick={(e) => handleClickSpecificMention(e)}>Responsable de traitement</Button>
                                <Button id='FT' variant="outlined" color={mention.id !== 'FT' ? 'primary' : 'secondary'} style={{ color: mention.id !== 'FT' ? '#3f51b5' : 'rgb(240, 52, 108)', margin: "0em 1em 1em 0em" }} onClick={(e) => handleClickSpecificMention(e)}>Finalité</Button>
                                <Button id='BS' variant="outlined" color={mention.id !== 'BS' ? 'primary' : 'secondary'} style={{ color: mention.id !== 'BS' ? '#3f51b5' : 'rgb(240, 52, 108)', margin: "0em 1em 1em 0em" }} onClick={(e) => handleClickSpecificMention(e)} >Bases Juridiques</Button>
                                <Button id='DC' variant="outlined" color={mention.id !== 'DC' ? 'primary' : 'secondary'} style={{ color: mention.id !== 'DC' ? '#3f51b5' : 'rgb(240, 52, 108)', margin: "0em 1em 1em 0em" }} onClick={(e) => handleClickSpecificMention(e)}>Les données et leur conservation</Button>
                                <Button id='DD' variant="outlined" color={mention.id !== 'DD' ? 'primary' : 'secondary'} style={{ color: mention.id !== 'DD' ? '#3f51b5' : 'rgb(240, 52, 108)', margin: "0em 1em 1em 0em" }} onClick={(e) => handleClickSpecificMention(e)}>Les destinataires des données</Button>
                                <Button id='VD' variant="outlined" color={mention.id !== 'VD' ? 'primary' : 'secondary'} style={{ color: mention.id !== 'VD' ? '#3f51b5' : 'rgb(240, 52, 108)', margin: "0em 1em 1em 0em" }} onClick={(e) => handleClickSpecificMention(e)}>Vos droits</Button>
                            {displayMention()}
                        </div>
                        :
                        ''
                    }
                    </>     
                 }          
        </div>
        <Footer pos="relative" bot={-310} color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'}/>
   </>
}

export default Contact