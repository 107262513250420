import React, {useEffect , useState } from "react"
import '../../styles/spaceuser.css'
import { useMediaQuery } from '../../main/hooks';
import { connect } from 'react-redux'
import { TabBar, Main } from "../../components/index"
import { check } from "../../utils/Generator"
import { TimeoutScreen } from "../index"
import { Footer } from "../../components/index"
import { setAccessBills }from "../../actions/spaceUserAction"
import { push } from 'connected-react-router'



const SecondScreen = ({ dispatch, userDataD, billsD, billsS, rowsM, errors, errorsDTL, pageNumber, dataTransaction, rows, errorsPreTrans, accessBill, bread, email }) => {
 
    const StatusTok = check()
    const mediaMatch = useMediaQuery('(max-width: 850px)');
    window.onload = () => {
        dispatch(push('/spaceuser'))
        dispatch(setAccessBills('/spaceuser/bills', 'Mes \u00c9ch\xe9ances', false))
    }
 
    const [loading, setloading] = useState(false)
    useEffect(() => {
        setloading(true)
    }, [])
 
    return (
    StatusTok === 200
        ?
            loading
            ?
             !mediaMatch
                ?
            <div className="containerbis">
                <Main
                    {...{
                        dispatch,
                        errors: errorsDTL,
                        errorsPreTrans,
                        rows,
                        userDataD,
                        dataTransaction,
                        pageNumber,
                        billsS,
                        accessBill,
                        bread,
                        email
                    }}
                />
                    {accessBill 
                    ?
                    <Footer pos="relative" bot={-100}  color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'}/>
                    :
                    <Footer pos="relative" bot={-275}  color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'}/> 
                    }
            </div>
                :
                <div className="mobileContainer" style={{width: "100vw", position:"relative"}}>
                        <TabBar {...{ dispatch, billsD, rowsM, errors, errorsPreTrans, userDataD, pageNumber, dataTransaction, email }}/> 
                    <Footer pos="relative" bot={-400} color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'}/>
                </div>
            :
            <div>En cours de chargement...</div>
        :
        <TimeoutScreen />
        )
}

const mapStateToProps = (state) => ({
    userDataD: state.idt.userData,
    email: state.idt.email,
    billsD: state.bls.billsData,
    billsS: state.dtl.billsData,
    accessBill: state.dtl.accessBills.isBill,
    dataTransaction: state.pat.dataTransaction,
    pageNumber: state.bls.pageNumber,
    rowsM: state.bls.rowsPermobile,
    errors: state.bls.errors,
    errorsDTL: state.dtl.errors,
    errorsPreTrans: state.pat.errors,
    rows: state.bls.rowsPer,
    bread: state.dtl.accessBills.breadCrumbs
});


export default connect(mapStateToProps)(SecondScreen);
