import React from "react"  

const ChiefTreatment = () => {

    return (
        <div style={{ color: "rgb(4, 60, 124)" }}>
            in’li PROPERTY MANAGEMENT est le responsable de traitement.
        </div>
        )


}

export default ChiefTreatment