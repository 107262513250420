import React from "react"
import ReduceNavBar from "../header/ReduceNav"
import Styles from "../../../styles/privacy"
import Footer from "./index"

const CookiePolicy = (props) => {

    return (
        <>
            <ReduceNavBar bgColor="#F0346C" title="Politique Cookies" color="white" check={props.check} dispatch={props.dispatch} />
            <div style={Styles.root}>
                <h6 style={Styles.h6bis}>qui sommes-nous ?</h6>

                <p>Le site <i>paiement.inlipm.fr</i> est la propriété d’in’li PROPERTY MANAGEMENT, dont le siège social est situé 5 place de la Pyramide La Défense 9, 92088  Paris la Défense Cedex.</p> 

                <h6 style={Styles.h6bis}>utilisation des cookies</h6>

                <p>Dans l’objectif notamment de rendre les services du site plus performants, vous êtes informé que le site utilise des cookies ou autres traceurs.</p> 

                <h6 style={Styles.h6bis}>pourquoi cette politique cookies ?</h6>

                <p>Dans un souci d’information et de transparence, 
                in’li PROPERTY MANAGEMENT a défini la présente Politique afin de vous permettre d’en savoir plus sur l’origine et la finalité des informations traitées 
                à l'occasion de votre navigation sur le site.</p>

                <h6 style={Styles.h6bis}>qu'est-ce qu'un cookie ?</h6>

                <p>Les cookies, ou autres traceurs similaires, sont des données utilisées par un serveur pour envoyer des informations d’état au navigateur d’un utilisateur, et par ce navigateur pour renvoyer des informations d’état au serveur d’origine. 

                Les cookies permettent de communiquer, pendant leur durée de validité, des informations d'état lorsqu’un navigateur accède aux différentes pages d'un site Web ou lorsque ce navigateur retourne ultérieurement sur ce site Web. Seul l’émetteur d’un cookie peut lire ou modifier les informations qui y sont contenues.

                Il existe différents types de cookies :
                les cookies de session qui sont temporaires et supprimés dès que vous quittez votre navigateur ou le site,
                les cookies permanents qui restent sur votre terminal jusqu’à expiration de leur durée de vie ou jusqu’à ce que vous les supprimiez à l’aide des fonctionnalités de votre navigateur.</p>

                <h6 style={Styles.h6bis}>Les informations</h6> 

                <p>Les informations d’état traitées peuvent être par exemple un identifiant de session, une langue, une date d’expiration, 
                un domaine de réponse, un identifiant unique, la date, l'heure de la connexion et/ou de la navigation, le type de navigateur,
                la langue du navigateur, l’adresse IP.</p>

                <h6 style={Styles.h6bis}>pourquoi utiliser les cookies ?</h6>

                <p>Nous utilisons des cookies et autres traceurs pour les principales finalités suivantes :
                effectuer des mesures et des analyses d’audience et de fréquentation et de votre navigation sur notre site à des fins notamment d’amélioration de nos services, comptabiliser les visiteurs et identifier la manière dont ils utilisent le site,
                sauvegarder vos choix en matière de cookies,
                adapter l’affichage et optimiser l’expérience utilisateur selon vos préférences,
                mettre en œuvre des mesures de sécurité,
                personnaliser le contenu affiché selon votre navigation sur le site.</p>


                <h6 style={Styles.h6bis}>consentement</h6>

                <p>Les cookies précités qui sont installés ne nécessitent pas votre consentement.</p>
            </div>
            <Footer pos="relative" bot={-310} color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'} />
            </>
        
        )

}

export default CookiePolicy