import {createStore, applyMiddleware, compose} from 'redux';
import reducer from '../reducers';
import { persistStore, persistReducer } from 'redux-persist'
import ReduxThunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage' 
import { routerMiddleware } from 'connected-react-router';
import history from './history'
import createEncryptor from 'redux-persist-transform-encrypt'

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//if user quit the app

const encryptor = createEncryptor({
  secretKey: process.env.REACT_APP_REDUX_ENC,
  onError: function(error) {
    // Handle the error.
  }
})
const composeEnhancersProd = compose;
const middleware = composeEnhancersProd(applyMiddleware(
    routerMiddleware(history),
    ReduxThunk,
));

const persistConfig = {
  key: 'app-react',
  storage,
  transforms: [encryptor]
}


const persistedReducer = persistReducer(persistConfig, reducer(history))


const store = createStore(
  persistedReducer,
  middleware
);


const persistor = persistStore(store)


export {store, persistor, persistConfig};

