const Styles = {
    root: {
        padding:"2em"
    },
    h6: {
        color: "rgb(4, 60, 124)"
    },
    h6bis: {
        color: "rgb(4, 60, 124)",
        textTransform: "uppercase"
    }
}

export default Styles 