import React from "react"
import { connect } from 'react-redux'
import { NotFound } from "../../components/index"


const NotFoundScreen = ({dispatch}) => (
      <NotFound/>
)


const mapStateToProps = (state) => ({

});


export default connect(mapStateToProps)(NotFoundScreen);

