const Styles = {
    divContent: {
        color: '#043C7C',
        textAlign: "left",
        margin: "5em 0em auto 4em",
    },
    formContain: {
        marginLeft:"0em",
        paddingTop: "2em",


    },
    buttonDiv: {
        margin: "10em 1em 1em 0em",
    }
}


export default Styles