import React, { useEffect } from "react"
import history from "../../main/history"
import { connect } from "react-redux"


const SecondScreenForce = ({ success }) => {
    useEffect(() => { return success ? history.push('/spaceuser') : "" }, [success])
        return <div>En cours de chargement....</div>
}


const mapStateToProps = (state) => ({
    success: state.idt.success,
});


export default connect(mapStateToProps)(SecondScreenForce);