import React from "react"
import ReduceNavBar from "../header/ReduceNav"
import Footer from "./index"

const Legacy = (props) => {

       return <>
           <ReduceNavBar bgColor="#F0346C" title="Mentions légales" color="white" check={props.check} dispatch={props.dispatch} />
           <div style={{maxWidth:"50em", margin: "0 auto", padding: "2em"}}>
               <p>« Conformément aux dispositions des Articles 6 et 19 de la Loi n°2004-575 du 21 juin 2004 pour la
               Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs
               et visiteurs du site paiement.inlipm.fr  les présentes mentions légales. »</p>


               <h6 style={{ marginTop: "5em", color:"rgb(4, 60, 124)"}}>EDITEUR</h6>
               <p>Le site <i>paiement.inlipm.fr</i> est édité par in’li PM.</p>
               <p>Raison sociale : in’li Property Management</p>
               <p>Forme juridique : Société par Actions Simplifiée</p>
               <p>Capital social : 225.000 euros</p>
               <p>Siège social : Tour ariane - 5, pl. de la Pyramide La Défense 9 92088 Paris la Défense CEDEX</p> 
               <p>Numéro RCS : RCS 712 049 774 RCS Nanterre</p>
               <p>Numéro TVA Intra : FR8712049774</p>
               <p>Directeur de publication : Antoine Pinel – Président</p>
               <p>Carte Professionnelle Gestion : CPI 9201 2018 000 036 042</p>

           </div>
           <Footer pos="absolute" bot={-800} color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'}/>
       </>
}

export default Legacy