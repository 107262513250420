import React, { useState, useEffect, lazy, Suspense }  from "react"
import NavBar from "../layouts/header/index"
import UserPersonalInfo from "./UserPersonalInfo.js"
import {  Paper } from "@material-ui/core"
import useStyles, { StylesSpaceComponent } from "../../styles/spaceuser"

const ButtonBills = lazy(() => import('./ButtonBills'));


const Main = (props) => {

    const { dispatch, accessBill, userDataD } = props
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
    }, [])
 
    return (
        loading
            ?
        <div className={classes.root}>
            <NavBar {...{ dispatch, accessBill}} />
            <div className="containerComp" style={StylesSpaceComponent.containerComp}>
                <div  className="userInfodiv" style={StylesSpaceComponent.userInfodiv}>
                    <Paper elevation={1} className={classes.paperMain}>
                        <UserPersonalInfo {...userDataD} />
                    </Paper>
                 </div>
                    <div>
                        <Suspense fallback={<div>Chargement...</div>}>
                            <ButtonBills {...props} />
                        </Suspense>
                </div>
            </div>
        </div>
            :
         <div>Page en cours de chargement...</div>
     )
}

export default Main