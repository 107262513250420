import React from "react"
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';


function BrowserSupportBody() {

    return (
        <div style={{ margin: "0 auto", textAlign: "center", width: "30em" }}>
            <div style={{ color: "rgb(4, 60, 124)" }}>
                <EmojiObjectsIcon />
                <h6>Pourquoi l'application ne fonctionne t-elle pas sous Internet Explorer  ?</h6>
            </div>
            <div>
                <p>Pour une meilleure expérience utilisateur notre application fait appel aux dernières technologies.</p>
                <p>Certaines versions de navigateurs, notamment Internet explorer ne supportent pas les dernières nouveautés et standards du web.</p>
            </div>
            <div style={{ color: "rgb(4, 60, 124)" }}>
                <EmojiObjectsIcon/>
                <h6>Quelles solutions ?</h6>
            </div>
            <div>
                <p>Nous vous invitons, à utiliser des navigateurs modernes tels que Chrome, Firefox, Safari, ...</p>
                <p>Pour votre sécurité vérifiez que votre navigateur est à jour avec la dernière version disponible.</p>
            </div>
            <div style={{ color: "rgb(4, 60, 124)"}}>
                <EmojiObjectsIcon />
                <h6>Nous contacter</h6>
            </div>
            <div style={{ marginBottom: "3em" }}>
                <h6>In’li Property Management</h6>
                <p>Service Clients</p>
                <p>Tour Ariane - 5, place de la Pyramide</p>
                <p>La Défense 92088 PARIS LA DEFENSE CEDEX</p>
                <p>Tél. : 01 76 24 14 14</p>
                <a href={`mailto:serviceclients@inlipm.fr`}>serviceclients@inlipm.fr</a>
            </div>
        </div>
    )

}

export default BrowserSupportBody