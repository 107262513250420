import React, { useState } from "react"
import ChoiceCard from "../../../../assets/images/help/choicecard.png"
import InfoCard from "../../../../assets/images/help/infocardpayzen.PNG"
import { StylesHelpConnexion } from "../../../../styles/faq"
import ImageDialog from "./ImageDialog"
import ReduceNavBar from "../../header/ReduceNav"
import { Link } from "react-router-dom";



const HelpPayment = (props) => {
    const [open, setOpen] = useState(false);
    const [val, setVal] = useState('');
    const handleClickHelper = (value) => {
        setOpen(true)
        setVal(value)
    }

    const handleClickHelperClose = () => {
        setOpen(false);
    }

    return <>
        <ReduceNavBar bgColor="#F0346C" title="Payer mes échéances" color="white" check={props.check} dispatch={props.dispatch} faq={true} />
        <h6 style={StylesHelpConnexion.h6}>Procédures de paiement sous payzen</h6>
        <div className="divDataCo" style={StylesHelpConnexion.divDataCo}>
            <p>Nous avons vu dans la partie <Link to='/helpforbills'>consulter mes échéances</Link>, qu'en arrivant au détail d'une échéance nous avions un bouton "Payer" permettant d'amorcer une procédure sécurisée de paiement sur payzen.</p>
            <p>En cliquant sur ce bouton (que ce soit sur mobile ou PC), vous arrivez sur l'interface payzen qui va vous demander de choisir un moyen de paiement, (voir image intitulée "Choix de cartes").</p>
            <p>Puis, il vous faudra, renseigner vos données de carte bancaire, nécessaire au paiement (voir image intitulée "Renseignement CB"). À la validation voir pourrez télécharger votre reçu et retourner sur l'application inli'PM en cliquant sur le bouton "retourner à la boutique" </p>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={ChoiceCard} alt="pics cards choice on payzen" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgpay1')} />
                <p><i>Choix de cartes</i></p>
            </div>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={InfoCard} alt="pics info cb on payzen" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgpay2')} />
                <p><i>Renseignement CB</i></p>
            </div>
        </div>
        <ImageDialog close={handleClickHelperClose} op={open} title={val} />
    </>

}

export default HelpPayment