import { makeStyles } from '@material-ui/core/styles'
import Background from "../assets/images/logement.jpg"
const useStyles = makeStyles({
    div: {
        width: "100vw",
    },
    divheaderimg: {
        backgroundImage: `url(${Background})`,
        height: "15vh",
        marginTop: "2em",
        minWidth: "100vw",
    },
    divlogo: {
       float:"left"
    },
    divicon: {
        float:"right",
        marginRight: "1em",
    },
    diviconbis: {
        float:"right",
        //marginRight: "2em",
    },
    icon: {
        marginTop: "1em",
        color: "#F0346C",
    }

});
export const Styles = {
    imglogo: {
        width: 200,
        marginTop: "1em"
    }
}


export const StylesProps = (props) => ({
 divheaderReduce: {
        height: "25vh",
        marginTop: "2em",
        marginBottom: "1em",
        minWidth: "100vw",
        backgroundColor: props.bgColor,
        color: props.color,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
        padding: "1em"
    },
})

export default useStyles