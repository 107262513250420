import React from "react"
import ReduceNavBar from "../header/ReduceNav"
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Footer from "./index"
import Styles from "../../../styles/faq"

const scrollToRef = () => window.scrollTo(0, 0) 

const Faq = (props) => {
    const executeScroll = () => scrollToRef()

    return <>
        <ReduceNavBar bgColor="#F0346C" title="Foire aux questions" color="white" check={props.check} dispatch={props.dispatch} />
        <div className='mainLinkFaq' style={Styles().divMain}>
            <div className="link1" style={Styles().divlink}>
                <ArrowForwardIcon />
                <Link to='/helpforconnexion' style={Styles().link} onClick={executeScroll}>Aide à la connexion</Link>
            </div>
            <div className="link2" style={Styles().divlink}>
                <ArrowForwardIcon />
                <Link to='/helpforspaceuser' style={Styles().link} onClick={executeScroll}>Mon espace</Link>
            </div>
            <div className="link3" style={Styles().divlink}>
                <ArrowForwardIcon />
                <Link to='/helpforbills' style={Styles().link} onClick={executeScroll}>Consulter mes écheances</Link>
            </div>
            <div className="link4" style={Styles().divlink}>
                <ArrowForwardIcon />
                <Link to='/helpforpayment' style={Styles().link} onClick={executeScroll}>Payer mes échéances</Link>
            </div>
        </div>
        <Footer pos="absolute" bot={-260} color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'}/>
        </>
}

export default Faq