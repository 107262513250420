import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Box, Tab, Tabs, Typography, IconButton, Paper  } from '@material-ui/core';
import { Payment, PersonPin } from '@material-ui/icons';
import Profil from "./Profil"
import InliLogo from "../../assets/images/inli.svg"
import useStyles from "../../styles/mobile"
import { useMediaQuery } from '../../main/hooks'
import { thunkBillsNoPush } from "../../actions/billsAction"
import TouchAppIcon from '@material-ui/icons/TouchApp';

const MobileBills = lazy(() => import('./MobileBills'));

const  TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

const TabBar = (props) => {
    const { dispatch, userDataD, billsD, errors, errorsPreTrans, rowsM, pageNumber, dataTransaction, email} = props
    const mediaMatch = useMediaQuery('(max-height: 380px)');
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [scroll, setScroll] = useState(false)
    const [scrollToTop, setScrollToTop] = useState(false)
    const [interval, setInterval] = useState(0)
    const [position, setPosition] = useState(150)

    useEffect(() => {
        setLoading(true)
        dispatch(thunkBillsNoPush(userDataD))
    }, [dispatch, userDataD])

    window.onclick = () => {
            if(scrollToTop) {
            window.scrollTo(0, position-10)     
        }
    }
    const handleScrollToTop = () =>  {
        const val = setInterval(() => { window.scrollTo(0, window.scrollY - 50)}, 50)
        setInterval(val)
    }
    window.onscroll = (event) => {
        if (window.location.pathname !== "/") {
            event.preventDefault()
            if (!scroll) {
                setScroll(true)
            }
            if (window.scrollY === 0) {
                setScroll(false)
                setScrollToTop(false)
                clearInterval(interval)
            }
            if (scrollToTop) {
                handleScrollToTop(interval)
            }
            setPosition(window.scrollY)
        } else {
            return null
        }
    }
    const handleScroll = (event) => {
        event.preventDefault()
        setScrollToTop(true)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }


    return (
           loading 
            ?
             <>
  
                <Paper square className={classes.panel}  onScroll={handleScroll}>
                    {scroll  
                    ?
                    !mediaMatch
                       ?
                        <IconButton  onClick={handleScroll} style={{position: "absolute", top: position + 192, right: 5,  color:"white",  background:"#043C7C", border: "0.7px solid #F8CDDA", opacity: 0.4}}>
                            <TouchAppIcon/>
                        </IconButton >
                       :
                        <IconButton  onClick={handleScroll} style={{position: "absolute", top: position + 190, right: 30 ,  color:"white",  background:"#043C7C", border: "0.7px solid #F8CDDA", opacity: 0.4}}>
                            <TouchAppIcon />
                        </IconButton >
                    :
                    ""}
                    <div className={classes.logodiv}>
                        <img src={InliLogo} className={classes.logo} alt="logo inli"/>
                    </div>
                     
                    <TabPanel value={value} index={0}>
                        <Suspense fallback={<div>Chargement...</div>}>
                            <MobileBills  
                                billsD={billsD}
                                rows={rowsM}
                                dispatch={dispatch}
                                errors={errors}
                                errorsPT={errorsPreTrans}
                                pageNum={pageNumber}
                                userData={userDataD}
                                dataTransaction={dataTransaction}
                                email={email}
                            />
                        </Suspense>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Profil {...{data: userDataD, dispatch}}/>
                    </TabPanel>
                </Paper>
                <Paper square className={classes.tabnav}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        indicatorColor="secondary"
                        textColor="secondary"
                        aria-label="icon label tabs"
                    >
                        <Tab icon={<Payment />} label="ECHEANCES"  />
                        <Tab icon={<PersonPin />} label="PROFIL" />
                    </Tabs>
                </Paper>
            </>
        :
        <div>Loading...</div>
    );
}

export default TabBar