import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
export const ColorButton = withStyles({
  root: {
    color: "#00AF98",
    marginTop:"1em",
    '&:hover': {
      backgroundColor: "lightblue",
    },
  },
})(Button);

const Styles = {
    area: {
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2em",
        display: "flex",
        flexDirection: "column",
        width: "20%",
        margin: "0 auto"
    }
}

export default Styles