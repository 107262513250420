import React from "react"
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import ImCoEmpty from "../../../../assets/images/help/connexionEmpty.PNG"
import ImCoFilled from "../../../../assets/images/help/connexionFilled.png"
import ImErrId from "../../../../assets/images/help/errorID.PNG"
import ImErrNet from "../../../../assets/images/help/errorNetwork.PNG"
import UserSpace from "../../../../assets/images/help/userspace.png"
import Profil from "../../../../assets/images/help/profilmobile.png"
import BillsOnWeb from "../../../../assets/images/help/billsdisplayonweb.png"
import DetailBillsOnWeb from "../../../../assets/images/help/detailbillonweb.png"
import BillsOnMob from "../../../../assets/images/help/billTopaymobile.PNG"
import DetailBillsOnMob from "../../../../assets/images/help/payexpandonmobile.PNG"
import Generality from "../../../../assets/images/help/displayBillsChoice.PNG"
import ChoiceCard from "../../../../assets/images/help/choicecard.png"
import InfoCard from "../../../../assets/images/help/infocardpayzen.PNG"
import EmailWeb from "../../../../assets/images/help/emailNeeded.PNG"
import EmailMob from "../../../../assets/images/help/emailFieldMobile.PNG"

const ImageDialog = (props) => {
    const { close, op, title } = props

   return <Dialog onClose={close} aria-labelledby="dialog-main-screen" open={op}>
       <DialogContent>
           {title === "img1"
               ?
               <img src={ImCoEmpty} alt="pics connexion empty" style={{ width: "20em" }} />
               :
               title === "img2"
               ?
                   <img src={ImCoFilled} alt="pics connexion filled" style={{ width: "20em" }} />
                   :
                   title === "img3"
                       ?
                       <img src={ImErrId} alt="pics error id" style={{ width: "30em" }} />
                       :
                       title === "img4"
                           ?
                           <img src={ImErrNet} alt="pics error network" style={{ width: "30em" }} />
                           :
                           title === "imgspace1"
                               ?
                               <img src={UserSpace} alt="pics space user on desktop" style={{ width: "50em" }} />
                               :
                               title === "imgspace2"
                                   ?
                                   <img src={Profil} alt="pics space user on mobile" style={{ width: "20em" }} />
                                   :
                                   title === "imgbills1"
                                       ?
                                       <img src={BillsOnWeb} alt="pics bills list on desktop" style={{ width: "40em" }} />
                                       :
                                       title === "imgbills2"
                                           ?
                                           <img src={DetailBillsOnWeb} alt="pics details bill on desktop" style={{ width: "40em" }} />
                                           :
                                           title === "imgbills3"
                                               ?
                                               <img src={BillsOnMob} alt="pics bills list on mobile" style={{ width: "20em" }} />
                                               :
                                               title === "imgbills4"
                                                   ?
                                                   <img src={DetailBillsOnMob} alt="pics details bill on mobile" style={{ width: "20em" }} />
                                                   :
                                                   title === "imgbills5"
                                                       ?
                                                       <img src={Generality} alt="pics choice display bill" style={{ width: "20em" }} />
                                                       :
                                                       title === "imgpay1"
                                                           ?
                                                           <img src={ChoiceCard} alt="pics cards choice on payzen" style={{ width: "40em" }} />
                                                           :
                                                           title === "imgEmail1"
                                                               ?
                                                               <img src={EmailWeb} alt="pics email" style={{ width: "50em" }} />
                                                               :
                                                               title === "imgEmail2"
                                                                   ?
                                                                   <img src={EmailMob} alt="pics email mob" style={{ width: "20em" }} />
                                                                   :
                                                                    <img src={InfoCard} alt="pics info cb on payzen" style={{ width: "30em" }} />




           }

        </DialogContent>
        <DialogActions>
            <Button onClick={close} color="primary" autoFocus size={'small'}>
                Fermer
            </Button>
        </DialogActions>
    </Dialog>
}

export default ImageDialog