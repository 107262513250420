import { makeStyles } from '@material-ui/core/styles';

export const styleMobileBills = {
    paginatorMob: {
        textAlign:"center",
    },
    errors: {
        marginTop:"4em", 
        textAlign:"center",
        color: "#F0346C"
    },
    imgError: {
        width:"100px", 
        marginRight:"2em"
    },
    logo: {
        width:"200px", 
    },
    logodiv: {
        marginTop:"4em", 
        textAlign:"center",
    },
    cardHeader: {
        color: "#043C7C"
    },
    cardContent: {
        color: "grey",
        //opacity: 0.4
    }
}

export const FormPostPaymentStyles = {
    buttonP: {
        margin: "1em" 
    }
}
export const styleMobileProfil = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: "6em"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  color: 'red'
});

const useStyles = makeStyles({
    panel: {
        flexGrow: 1,
        overflow: "auto",


    },
    tabnav: {
        flexGrow: 1,
        position: "fixed",
        overflow: "hidden",
        top: 0,
        width: "100%"
       
    },
    logo: {
        width:"200px", 
    },
    logodiv: {
        marginTop:"4em", 
        textAlign:"center",
    },
    cardBills: {
        margin:"1em",
      
    },
    cardReverse: {
        display: "inline-block",
        alignItems:"center",
        margin:"0.8em",
        width:"40%"
    }
});

export const useStylesFormEmail = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "80%"

        }
    },
}))

export const StylesFormEmail = {
    root: { margin:  "0.5em"}
}

export default useStyles