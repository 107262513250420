import React from "react"
import { Divider, Typography } from "@material-ui/core"
import useStyles from "../../styles/spaceuser"
import { IconButton, Tooltip } from '@material-ui/core'
import { Edit } from '@material-ui/icons';
import { yellow } from '@material-ui/core/colors';

const UserPersonalInfo = (props) => {
    const { address, city, fullName, email, leaseNumber, buildingCode, phoneNumber, zipCode} = props
    const classes = useStyles()
    const serviceContactEmail = `L' email renseign\u00e9 vous permettra de recevoir vos re\u00e7us. Afin de ne pas \u00e0  avoir \u00e0  renseigner votre email \u00e0  chaque connexion, nous vous invitons \u00e0  contacter le service client.`
    const serviceContactTel = `Veuillez communiquer votre t\u00e9l\u00e9phone aupr\u00e8s du service client.`
    return (
        <>
            <Typography variant={'body1'} className={classes.titleWelcome}>
                Bienvenue {fullName}
            </Typography>
            <Divider  className={classes.divider}/>
                <p>
                   Code bail : {leaseNumber}
                </p>
                <p>
                  Code d'immeuble : {buildingCode}
                </p>
                <p>
                Email :  {email !== null ? email : <Tooltip title={serviceContactEmail}>
                    <IconButton size='small'  aria-label="email icon">
                        <Edit style={{ color: yellow[800] }} />
                    </IconButton>
                </Tooltip> }
           
                </p>
                <p>
                T&#233;l&#233;phone :  {phoneNumber !== null ? phoneNumber : <Tooltip title={serviceContactTel}><IconButton size='small'  aria-label="email icon">
                    <Edit style={{ color: yellow[800] }} />
                </IconButton>
                </Tooltip>}
       
                </p>
                <Divider className={classes.divider}/>
                <p>
                {address}
                </p>
                <p>
                {zipCode}
                </p>
                <p>
                {city}
                </p>
        </>
     )
    
}


export default UserPersonalInfo