import { payment } from "./types"
import axios from "axios"
import { batch } from 'react-redux'
export const setError = (id, message) => ({
    type: payment.SET_ERROR_PAT,
    id,
    message
})

export const retrievePreData = (value) => ({
    type: payment.RETRIEVE_PREDATA_TRANSACTION,
    value
})


export const thunkPayement = (value, valuebis) => {
    var storeId = JSON.parse(process.env.REACT_APP_STOREID)

    return (dispatch, getState) => {

        const paymentPre = {
            "customerId": "string",
            "customerFullName": valuebis.fullName,
            "customerEmail": getState().idt.email,
            "customerPhoneNumer": valuebis.phoneNumber,
            "customerAddress": valuebis.address,
            "customerZipCode": valuebis.zipCode,
            "customerCity": valuebis.city,
            "orderNumber": `${value.buildingCode}-${value.leaseNumber}-${value.billNumber}`,
            "orderAmount": value.remainingAmount,
            "paymentMode": 0,
            "storeId": Object.keys(storeId).includes(value.ownerCode) ? storeId[parseInt(value.ownerCode)] : 0,
            "productId_1": value.billNumber,
            "productLabel_1": `Facture ${value.billNumber}`,
            "productAmount_1": value.billingAmount
        }
        const form = new FormData();
        if (Object.entries(getState().pat.dataTransaction).length > 0) {
            Object.entries(getState().pat.dataTransaction).map((el, index) => (
                form.append(el[0],el[1])
            ))
        }
 
        axios.post(`${process.env.REACT_APP_API2_ENDPOINT}/api/Transaction`, paymentPre ,{ 
            headers: {
                     "X-API-KEY": process.env.REACT_APP_API_KEY2 
            } 
        })
        .then(response => {
            if (response.status === 200) {
                //put data return by api/transaction
                batch(() => {
                    dispatch(setError(404, ''))
                    dispatch(setError(209, ''))
                    dispatch(retrievePreData(response.data))
                })
            } 
        })
            .catch((e) => {
                if (!Object.keys(storeId).includes(value.ownerCode)) {
                    dispatch(setError(209, 'Le service de paiement électronique est indisponible pour ce bail. Merci de réessayer ultérieurement.'))  
                } else {
                    dispatch(setError(404, 'Erreurs survenues lors de la récupération des données de pre-transactions'))  
                }
              
        });
    }
}

