import React from "react"
import { Grid } from "@material-ui/core"
import  inliSvg from "../../../assets/images/inli.svg"
import useStyles, { Styles, StylesProps } from "../../../styles/navbar.js"


const ReduceNavBar = (props) => {
    const classes = useStyles();   
    return (
        <div className={classes.root}>
          <Grid container direction={'row'} spacing={3}>
            <div className={classes.div}>
                <div className={classes.divlogo}>
                    <img src={inliSvg} alt="logo" style={Styles.imglogo}/>
                </div>
             </div>
          </Grid>
          <Grid container direction={'row'}>
            <div style={StylesProps(props).divheaderReduce}>
               {props.title}
            </div>
          </Grid>
        </div>
    )
}

export default ReduceNavBar