import { batch } from 'react-redux'
//import history from "../main/history"
import { retrievePreData } from "./paymentAction"
import { removeBreadCrumbs, setAccessBills, getUserBillsData } from "./spaceUserAction"
import { setPageNum, setRowMob } from "./billsAction"
import { remove } from "../utils/Generator"
import { router } from "./types"
import { push } from 'connected-react-router'



export const clear = () => ({
    type: 'USER_LOGOUT'
})

export const clearRouter = () => ({
    type: router.RESET_ROUTE
})


/*export const thunkUserSpace = () => {
    return (dispatch, getState) => {
        batch(() => {
            dispatch(setPageNum(1))
            dispatch(retrievePreData({}))
            dispatch(getUserBillsData({}))
            dispatch(setAccessBills('', '', !getState().dtl.accessBills.isBill))
            dispatch(removeBreadCrumbs('/spaceUser/bills'))
        })
        history.push('/spaceUser')
        setTimeout(() => { window.location.reload() }, 0)
    }
}*/

export const thunkUserSpaceNoReload = () => {
    return (dispatch, getState) => {
        const countBills = getState().bls.billsData.length
        batch(() => {
            dispatch(setPageNum(1))
            /*only for mobile */
            dispatch(setRowMob(countBills))
            //for web
            dispatch(getUserBillsData({}))
            dispatch(setAccessBills('', '', false))
            dispatch(removeBreadCrumbs('/spaceuser/bills'))
            dispatch(retrievePreData({}))
            dispatch(push('/spaceuser'))
        })
    }
}

export const thunkBackFaq = () => {
    return (dispatch) => {
        dispatch(push('/faq'))

    }

}

export const thunkBackPrivacy = () => {
    return (dispatch) => {
        dispatch(push('/privacy'))

    }

}


export const thunkClearNoReload = () => {
    return (dispatch) => {
     batch(() => {
         dispatch(clear())
         dispatch(push('/'))
         //dispatch(clearRouter())
         remove()
        })
    }
}

export const thunkClearNoCookie = () => {
    return (dispatch) => {
        batch(() => {
            dispatch(clear())
            dispatch(clearRouter())
            dispatch(push('/'))
            remove()
        })
    }
}


export const thunkClear = () => {
    return (dispatch) => {
        batch(() => {
           // dispatch(clearRouter())
            dispatch(clear())
            dispatch(push('/'))
            remove()
        })
    }
}
