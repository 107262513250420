import jwt from "jsonwebtoken"
import Cookies from 'js-cookie';
import { store } from "../main/store"

//var inOneHourhandTwoMinutes = new Date(new Date().getTime() + 120 * 60 * 1000);
//test 1 hour
const generator = (values) => {
    var inOneHourhandTwoMinutes = new Date(new Date().getTime() + 120 * 60 * 1000);
    var token = jwt.sign({ data: values }, process.env.REACT_APP_KEY_TOK, { expiresIn: 60 * 60 })
    const cookie = Cookies.set(process.env.REACT_APP_COOK_NAME, token, { secure: true, expires: inOneHourhandTwoMinutes });

    return cookie;
}

export const remove = () => {
    Cookies.remove(process.env.REACT_APP_COOK_NAME)
}

export const check = () => {
    const token = Cookies.get(process.env.REACT_APP_COOK_NAME)
    const storeD = store.getState().idt.userData.leaseNumber + store.getState().idt.userData.buildingCode
 
    let resultValid = 200
    if (token !== undefined) {
         jwt.verify(token, process.env.REACT_APP_KEY_TOK, function (err, decoded) {
    
            if (decoded !== undefined) {
                const tokVal = decoded.data.leaseNumber + decoded.data.buildingCode 
                return tokVal !== storeD ? resultValid = 407 : resultValid = 200

            } else if (err) {
                resultValid = 404
            } else {
                resultValid = 200
            }
         });
    } else if (window.location.pathname === "/") {
        resultValid = 200
    } else {
        resultValid = 300
    }
    return resultValid

}

export default generator