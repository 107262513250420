import React from "react"
import { connect } from 'react-redux'
import { Timeout } from "../../components/index"


const TimeoutScreen = ({ dispatch, transaction, success}) => (
    <Timeout {...{ dispatch: dispatch, isTransaction: transaction, success}}/>

)
const mapStateToProps = (state) => ({
    transaction: state.pat.transaction,
    success: state.idt.success
});


export default connect(mapStateToProps)(TimeoutScreen);
