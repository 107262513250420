import { bills } from "../actions/types"

const defaultState = {
    rowsPer: 5,
    rowsPermobile: 2,
    billsData: {},
    errors: { 404 : ''},
    pageNumber: 1,
}

const BillsReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case bills.SET_ROWS:
            return {
                ...state,
                rowsPer: action.value
            }
         case bills.SET_ROWS_MOB:
            return {
                ...state,
                rowsPermobile: action.value
            }
        case bills.SET_PAGE_NUM:
            return {
                ...state,
                pageNumber: action.page
            }
        case bills.GET_BILLSDATA:
            return {
                ...state,
                billsData: action.value
            }
        case bills.SET_ERROR_BLS: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.id]: action.message
                }
            }
        }
        default:
            return state 
    }

}
export default BillsReducer