import React from "react"

const BaseJustice = () => {

    return (
        <div style={{ color: "rgb(4, 60, 124)" }}>
            <p>Le traitement est fondé sur l’intérêt légitime d’in’li PROPERTY MANAGEMENT.</p>
            <p>Les données vous concernant sont nécessaires, en leur absence nous ne pourrons pas répondre à vos demandes.</p>
        </div>
    )


}

export default BaseJustice