import React from "react"

const Data = () => {

    return (
        <div style={{ color: "rgb(4, 60, 124)" }}>
            <p>Les informations obligatoires pour répondre à votre question sont signalées par un astérisque.</p>
            <p>A défaut de ces informations, nous ne pourrons pas répondre à votre demande.</p>
            <p>Les données clients sont conservées pendant le délai nécessaire au traitement de la demande augmenté d’un an.</p>
        </div>
    )


}

export default Data