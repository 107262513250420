const Styles = (props) => ({
 MainFooter: {
    position: props.pos,
    right: 0,
    bottom: props.bot,
    left: 0,
    fontSize:"14px",
    padding: "1rem 2rem 0rem 0rem",
    backgroundColor: props.bgcolor,
    minWidth: "100vw"
  },
 ContainerLinkFooter: {
    display:"flex", 
    flexDirection: "row", 
    flexWrap: "wrap",
    justifyContent: "flex-end",  
    marginBottom: "1em",
  },
 link: {
    color: props.color, 
    marginLeft: "1em",
    marginRight: "0.7em",
    marginBottom: "0.2em",
    textDecoration: props.decoration,
    },
linkFaq: {
    color: props.color,
    marginLeft: "0.5em"
},
copyright: {
   color:props.colorbis, 
   marginLeft:"1em",
   marginTop: "0.6em"
},
})

export default Styles 