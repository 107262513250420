import React from "react"
import { Grid, IconButton, Typography   } from "@material-ui/core"
import  inliSvg from "../../../assets/images/inli.svg"
import useStyles, { Styles } from "../../../styles/navbar.js"
import { AccountCircle,  ExitToApp } from '@material-ui/icons'
import { thunkClear, thunkUserSpaceNoReload } from "../../../actions/mainAction"

const NavBar = (props) => {
    const { dispatch, accessBill } = props
    const classes = useStyles();

    const handleClick = (value) => {
        if(value === 'exit') {
         dispatch(thunkClear())
        } else {
        dispatch(thunkUserSpaceNoReload())
        }
    }

    return (
        <div className={classes.root}>
          <Grid container direction={'row'} spacing={3}>
            <div className={classes.div}>
                <div className={classes.divlogo}>
                    <img src={inliSvg} alt="logo" style={Styles.imglogo}/>
                </div>
                <div className={classes.diviconbis}>
                    <div>
                        <IconButton aria-label="account" className={classes.icon} onClick={() => handleClick('exit')}>
                            <ExitToApp/>
                        </IconButton>
                    </div>
                    <div>
                        <Typography variant="caption">Se Déconnecter</Typography>
                    </div>
                </div>
                    {accessBill
                        ?
                        <div className={classes.divicon}>

                            <div>
                                <IconButton aria-label="account" className={classes.icon} onClick={() => handleClick('')}>
                                    <AccountCircle />
                                </IconButton>
                            </div>
                            <div>
                                <Typography variant="caption">Mon Espace</Typography>
                            </div>
                        </div>
                        :
                        ''
                    }
             </div>
          </Grid>
          <Grid container direction={'row'}>
            <div className={classes.divheaderimg} />
          </Grid>
        </div>
    )

}


export default NavBar