import React from "react"
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core'
import { useMediaQuery } from '../../main/hooks';
import useStyles from "../../styles/identity"
import ImBills from "./ImBills"



const HelperDialog = (props) => {
    const { close, op, title } = props
    const mediaMatch = useMediaQuery('(max-width: 500px)');
    const classes = useStyles()
    return (
        !mediaMatch
        ?
        <Dialog onClose={close} aria-labelledby="dialog-main-screen" open={op} maxWidth={"md"} fullWidth={true}>
            <DialogTitle id="dialog-main-screen-title" className={classes.dialogTitle} >{title}</DialogTitle>
                <DialogContent>
                    <ImBills title={title} width={600} widthout={700} />
                </DialogContent>
                <DialogActions>
                <Button onClick={close} color="primary" autoFocus size={'small'}>
                      Fermer
                </Button>
            </DialogActions>
        </Dialog>
        :
        <Dialog onClose={close} aria-labelledby="dialog-main-screen" open={op}>
            <DialogTitle id="dialog-main-screen-title" className={classes.dialogTitle} >{title}</DialogTitle>
                <DialogContent>
                    <ImBills title={title}  width={400}  widthout={500} />
                </DialogContent>
                <DialogActions>
                <Button onClick={close} color="primary" autoFocus size={'small'}>
                      Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default HelperDialog