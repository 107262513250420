import React, { useState } from "react"
import BillsOnWeb from "../../../../assets/images/help/billsdisplayonweb.png"
import EmailWeb from "../../../../assets/images/help/emailNeeded.PNG"
import DetailBillsOnWeb from "../../../../assets/images/help/detailbillonweb.png"
import BillsOnMob from "../../../../assets/images/help/billTopaymobile.PNG"
import DetailBillsOnMob from "../../../../assets/images/help/payexpandonmobile.PNG"
import Generality from "../../../../assets/images/help/displayBillsChoice.PNG"
import EmailMob from "../../../../assets/images/help/emailFieldMobile.PNG"
import { StylesHelpConnexion } from "../../../../styles/faq"
import ImageDialog from "./ImageDialog"
import ReduceNavBar from "../../header/ReduceNav"
import { Link } from "react-router-dom";



const HelpSeeBills = (props) => {
    const [open, setOpen] = useState(false);
    const [val, setVal] = useState('');
    const handleClickHelper = (value) => {
        setOpen(true)
        setVal(value)
    }

    const handleClickHelperClose = () => {
        setOpen(false);
    }

    return <>
        <ReduceNavBar bgColor="#F0346C" title="Consulter mes échéances" color="white" check={props.check} dispatch={props.dispatch} faq={true} />
        <h6 style={StylesHelpConnexion.h6}>Consultation globale  et détaillée sous ordinateur</h6>
        <div className="divDataCo" style={StylesHelpConnexion.divDataCo}>
            <p>Lorsque vous cliquez sur la barre rouge <Link to='/helpforspaceuser'>consulter et payer mes échéances</Link>, un tableau listant l'ensemble de vos échéances est visible,  rappelant pour chacune le statut (à régler ou paiement en cours, voir image intitulée "Liste des échéances" , sur PC) </p>
            <p> En cliquant sur l'icône "euro" bleu dans la colonne action, vous accédez au détail de l'échéance séléctionnée avec la possibilité d'entamer la procédure de paiement (voir image intitulée "Détails d'une échéance sur PC")</p>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={BillsOnWeb} alt="pics bills list on desktop" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgbills1')} />
                <p><i>Liste des échéances, sur PC</i></p>
            </div>
            <p>Attention, si vous n'avez pas renseigné votre email vous verrez apparaître une icône "stylo" et non l'icône "euro", au clic un formulaire s'affiche et vous demande de renseigner votre
                email cela sera nécessaire pour recevoir votre reçu lors de la phase de <Link to='/helpforpayment'>paiement</Link> (voir image intitulée "Email non renseigné, sur PC")</p>
            <p>Nous vous invitons à contacter le service client pour ne pas à avoir à renseigner votre email à chaque connexion</p>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={EmailWeb} alt="pics email" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgEmail1')} />
                <p><i>Email non renseigné, sur PC</i></p>
            </div>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={DetailBillsOnWeb} alt="pics details bill on desktop" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgbills2')} />
                <p><i>Détails d'une échéance, sur PC</i></p>
            </div>
        </div>
        <h6 style={StylesHelpConnexion.h6}>Consultation globale  et détaillée sous mobile</h6>
        <div className="divDataCo" style={StylesHelpConnexion.divDataCo}>
            <p>Sous mobile, les échéances seront listées sous forme de carte , le statut est indiqué en dessous de la période de l'échéance, (voir image initulée "Liste des échéances, sous mobile")</p>
            <p>En cliquant sur le libellé "cliquer et payer" vous déroulerez le détail de l'échéance choisie et pourrez notamment amorcer la procédure de paiement, (voir image initulée "Details d'une écheance, sous mobile")</p>
            <p>Le bouton visible sur le côté droit de l'écran vous permettra au clic de remonter tout en haut de la page, ceci est pratique notamment si vous avez une grande liste d'échéances, (voir les deux images de la section "Consultation globale et détaillée sous mobile")</p>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={BillsOnMob} alt="pics bills list on mobile" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgbills3')} />
                <p><i>Liste des échéances, sous mobile</i></p>
            </div>
            <p>Attention, si vous n'avez pas renseigné votre email vous verrez apparaître le libellé "renseigner votre email" et non "cliquer et payer", au clic un formulaire s'affiche et vous demande de renseigner votre
                email cela sera nécessaire pour recevoir votre reçu lors de la phase de <Link to='/helpforpayment'>paiement</Link> (voir image intitulée "Email non renseigné, sur Mobile")</p>
            <p>Nous vous invitons à contacter le service client pour ne pas à avoir à renseigner votre email à chaque connexion</p>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={EmailMob} alt="pics email on mobile" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgEmail2')} />
                <p><i>Email non renseigné, sur Mobile</i></p>
            </div>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={DetailBillsOnMob} alt="pics details bill on mobile" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgbills4')} />
                <p><i>Détails d'une échéance, sous mobile</i></p>
            </div>
        </div>
        <h6 style={StylesHelpConnexion.h6}>Généralités</h6>
        <div className="divDataCo" style={StylesHelpConnexion.divDataCo}>
            <p>Vous pourrez choisir le nombre d'échéances souhaitées par page par le biais d'un paginateur situé en haut pour la version mobile (voir image intitulée "Choisir l'affichage des échéances") et en bas du tableau pour la version sous PC</p>
            <div style={StylesHelpConnexion.generalDiv}>
                <img src={Generality} alt="pics choice display bill" style={StylesHelpConnexion.generalImg} onClick={() => handleClickHelper('imgbills5')} />
                <p><i>Choisir l'affichage des échéances</i></p>
            </div>
        </div>
        <ImageDialog close={handleClickHelperClose} op={open} title={val} />
    </>

}

export default HelpSeeBills