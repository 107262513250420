import React from "react"
import ImgNotFound from "../../assets/outside/sadbot.png"
import Styles, { ColorButton } from "../../styles/errors.js"
import { thunkClear, thunkClearNoCookie } from "../../actions/mainAction"
import ReduceNavBar from "../layouts/header/ReduceNavError"
import LoaderCust from "../utils/LoaderCust"
import { check } from "../../utils/Generator"


const Timeout = (props) => {
    const { dispatch, success } = props
    const StatusTok = check()

   const handleRedirection =  (e) => {
     e.preventDefault()
       StatusTok === 407
           ?
       dispatch(thunkClearNoCookie())
           :
       dispatch(thunkClear())
   }

  return (
    <>
        {
        StatusTok === 300
        ?
        <ReduceNavBar 
            bgColor="#F0346C" 
            title="409 cookie de session expiré, en cas de reconnexion infructueuse 
             veuillez vérifiez que votre navigateur ne bloque pas la création de ce cookie nécessaire à la consultation de ce site" 
            color="white"/>
        :
        StatusTok === 407
            ?
        <ReduceNavBar bgColor="#F0346C" title="407 vous nêtes pas autorisé à consulter cette page" color="white" />
            :
        <ReduceNavBar bgColor="#F0346C" title="408 Session expirée" color="white"/>
        }
        <div className="area" style={Styles.area}>
              {
                success
                 ?
                <>
                    <img src={ImgNotFound} alt="not found" />
                    <ColorButton variant="outlined" color="primary" onClick={handleRedirection}>
                        Se reconnecter
                    </ColorButton>
                </>
                :
                <LoaderCust
                    type="BallTriangle"
                    color="#043C7C"
                    height={100}
                    width={100}
                />
            }
        </div>
    </>
    )


}

export default Timeout