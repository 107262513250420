import React from "react"
import {
    Divider
} from '@material-ui/core';
import { HelpTwoTone } from '@material-ui/icons';
import { Link } from "react-router-dom";
import Styles from "../../../styles/footer"


const scrollToRef = () => window.scrollTo(0, 0) 
const Footer = (props) => {
    const executeScroll = () => scrollToRef()

     return (
        <div  className='MainFooter' style={Styles(props).MainFooter}
          >
           <div className='ContainerLinkFooter' style={Styles(props).ContainerLinkFooter}>
                 <Link to='/contact' style={Styles(props).link} onClick={executeScroll}>Nous contacter</Link>
                 <Link to='/legacy' style={Styles(props).link} onClick={executeScroll}>Mentions Légales</Link>
                 <Link to='/personaldata' style={Styles(props).link} onClick={executeScroll}>Données personnelles</Link>
                 <Link to='/cookiepolicy' style={Styles(props).link} onClick={executeScroll}>Politique Cookies</Link>
                 <Link to='/faq' style={Styles(props).linkFaq} onClick={executeScroll}>Une Question <HelpTwoTone /></Link>
            </div>
               <Divider />
             <div className='copyright' style={Styles(props).copyright}>
                 <span>&#169;</span> 2020 Inli'PM. Tous droits réservés.
             </div>
        </div>
    )

}


export default Footer