import React from "react"
import ReduceNavBar from "../header/ReduceNav"
import Styles from "../../../styles/privacy"
import { Link } from "react-router-dom";
import Footer from "./index"

const PersonalData = (props) => {

    return (
        <>
            <ReduceNavBar bgColor="#F0346C" title="Données personnelles" color="white" check={props.check} dispatch={props.dispatch} />
            <div style={Styles.root}>
                <h6 style={Styles.h6}>Collecte et utilisation</h6>

                <p>In’li PROPERTY MANAGEMENT, responsable du traitement, dans le cadre de ce site internet met en œuvre un traitement de données vous concernant relatif à votre identification sur le site, au service de consultation et de paiement des échéances de charges et au suivi des réponses à vos demandes via le formulaire de contact. Ces traitements sont fondés sur l’intérêt légitime d’in’li PROPERTY MANAGEMENT et sur l’exécution de votre contrat.
                Les données collectées sont indispensables à ces traitements. Elles sont destinées aux services habilités d’in’li PROPERTY MANAGEMENT, le cas échéant à ses partenaires et prestataires. Certaines données peuvent être adressées à des tiers pour satisfaire aux obligations légales et réglementaires. Elles peuvent également être communiquées, à leur requête, aux organismes officiels et aux autorités administratives ou judiciaires, notamment dans le cadre de la lutte contre le blanchiment de capitaux ou contre le financement du terrorisme. in’li PROPERTY MANAGEMENT est tenue au secret professionnel concernant ces données.
                Les données liées à votre identification sont conservées pour un durée maximale de 2 heures. Les données sont conservées pendant le délai nécessaire au traitement de la demande augmenté d’un an.
                Des données indirectement nominatives, à usage statistique, peuvent également être collectées à des fins de gestion de votre connexion et de votre navigation
                    (voir  <Link to='/cookiepolicy'>notre Politique Cookies</Link>). </p>

                <h6>Sécurité des traitements</h6>

                <p>in’li PROPERTY MANAGEMENT prend les mesures physiques, 
                techniques et organisationnelles appropriées pour assurer la sécurité et la confidentialité des données à caractère personnel collectées,
                    en vue notamment de les protéger contre toute perte, destruction accidentelle, altération, et accès non autorisés.</p>

                <h6>Droits reconnus</h6>

                <p>Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 et du règlement UE 2016/679 du 27 avril 2016 en matière de protection des données à caractère personnel, dans les conditions définies par les dispositions précitées, vous disposez d’un droit d’accès, de rectification, d’effacement de vos données à caractère personnel, à la limitation du traitement, à la portabilité de vos données lorsque le traitement est fondé sur l’exécution du contrat ou le consentement.

                Lorsque votre consentement est sollicité, vous disposez du droit de retirer votre consentement, à tout moment, sans porter atteinte à la légitimité du traitement fondé sur le consentement effectué avant le retrait de celui-ci.

                Vous disposez du droit de s’opposer à tout moment, pour des raisons tenant à votre situation particulière, à un traitement de données à caractère personnel dont la base juridique est l’intérêt légitime poursuivi par le responsable du traitement ou à la prospection commerciale.

                En cas d’exercice d’un tel droit d’opposition, le responsable de traitement veillera à ne plus traiter les données à caractère personnel dans le cadre du traitement concerné sauf s’il peut démontrer qu’il peut avoir des motifs légitimes et impérieux pour maintenir ce traitement. Ces motifs devront être supérieurs aux intérêts et aux droits et libertés de l’utilisateur, ou le traitement se justifier pour la constatation, l'exercice ou la défense de droits en justice.

                Par ailleurs, vous disposez également du droit de définir des directives relatives à la conservation, à l’effacement et à la communication de vos données à caractère personnel après votre décès. La communication de directives spécifiques post-mortem et l’exercice des droits peuvent être exercés auprès du Délégué à la protection des données (DPO) d’in’li PROPERTY MANAGEMENT, joignable par courrier électronique à l’adresse suivante : dpoinlipm@inli.fr ou par courrier postal à l’adresse suivante : DPO d’in’li PROPERTY MANAGEMENT, Tour Ariane, 5 place de la Pyramide, 92088 Paris La Défense cedex.
                Lorsque vous adressez une demande d'exercice de droit, vous devez vous identifier par tout moyen. En cas de doute sur votre identité, le responsable de traitement pourra demander des informations supplémentaires apparaissant nécessaires, y compris la photocopie d'un titre d'identité portant la signature de la personne concernée.

                Vous avez également la possibilité d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL) à l’adresse suivante : 3 Place de Fontenoy, TSA 80715, 75334 Paris Cedex 07.

                Dans le cas où vous communiquez des coordonnées relatives à un de vos contact ou proche, vous vous engagez à lui transmettre les informations du présent article afin qu’il puisse être informé du traitement de ses données.  </p>

            </div>
            <Footer pos="relative" bot={-310} color={"#F0346C"} bgColor={'white'} colorbis={"#043C7C"} decoration={'underline'} />
            </>
        
        )
}

export default PersonalData