import React from "react"
import {IconButton, Grid , Typography} from "@material-ui/core"
import { ArrowBack } from '@material-ui/icons'
import  inliSvg from "../../../assets/images/inli.svg"
import useStyles, { Styles, StylesProps } from "../../../styles/navbar.js"
import { thunkUserSpaceNoReload, thunkClearNoReload, thunkBackFaq, thunkBackPrivacy } from "../../../actions/mainAction"


const ReduceNavBar = (props) => {

    const { check, dispatch, faq, policy} = props
    const classes = useStyles();
    const handleClick = () => {
        check && !faq && !policy
        ?
        dispatch(thunkUserSpaceNoReload())
            :
                faq
                ?
                dispatch(thunkBackFaq())
                :
                policy
                    ?
                    dispatch(thunkBackPrivacy())
                    :
                    dispatch(thunkClearNoReload())
    }   
    return (
        <div className={classes.root}>
          <Grid container direction={'row'} spacing={3}>
            <div className={classes.div}>
                <div className={classes.divlogo}>
                    <img src={inliSvg} alt="logo" style={Styles.imglogo}/>
                </div>
                     <div className={classes.diviconbis}>
                    <div>
                        <IconButton aria-label="account" className={classes.icon} onClick={() => handleClick()}>
                            <ArrowBack/>
                        </IconButton>
                    </div>
                    <div>
                        <Typography variant="caption">Précédent</Typography>
                    </div>
                </div>
             </div>
          </Grid>
          <Grid container direction={'row'}>
            <div style={StylesProps(props).divheaderReduce}>
               {props.title}
            </div>
          </Grid>
        </div>
    )
}


export default ReduceNavBar